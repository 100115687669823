<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#081A25"
        fill-rule="nonzero"
        d="M10 3C5.455 3 1.573 5.903 0 10c1.573 4.097 5.455 7 10 7s8.427-2.903
        10-7c-1.573-4.097-5.45-7-10-7zm0 11.667c-2.51 0-4.545-2.091-4.545-4.667
        0-2.576 2.036-4.667 4.545-4.667 2.51 0 4.545 2.091 4.545 4.667 0 2.576-2.036
        4.667-4.545 4.667zM10 8c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"
      />
      <path stroke="#081A25" stroke-linecap="round" stroke-width="2" d="M1 19L19 1" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DisableIcon',
};
</script>
