/* eslint-disable no-underscore-dangle */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
export default class ActiveRemoteConnectionsModel {
  /**
   * Creates an instance of ActiveRemoteConnectionsModel.
   * @param {Object} data - ActiveRemoteConnectionsModel
   * @class ActiveRemoteConnectionsModel
   * @property {string} id - Remote connection id
   * @property {string} node - Node name
   * @property {string} acknowledgment - Local acknowledgment
   * @property {string} target - Target
   * @property {string} user - User
   * @property {string} connection - Connection name
   * @property {string} serialNumber - Node serial number
   * @property {string} type - Remote connection type
   * @property {string} nodeStatus - Node status
   * @property {string} created Creation time
   */
  constructor(data = {}) {
    this.id = data._id;
    this.node = data.connection.target.nodeName || '';
    this.acknowledgment = data.connection.acknowledgment || '';
    this.target = data.connection.target.workloadOrHostname || '';
    this.user = data.connectionRequest.requestedBy || '';
    this.connection = data.name || '';
    this.serialNumber = data.connection.serialNumber || '';
    this.type = data.connection.type;
    this.connectionUid = data.connection.connectionUid;
    this.requestUid = data.connectionRequest.requestUid;
    this.nodeStatus = data.connection.nodeStatus;
    this.tunnelPort_screenType =
      data.connection.type === 'TUNNEL' ? data.connection.localPort : data.connection.connection;
    this.created = data.connectionRequest.created
      ? new Date(data.connectionRequest.created).toLocaleString('en-GB')
      : '';
    this.userId = data.connectionRequest.userId || '';
    this.nodeId = data.connection.target.nodeId || '';
    this.workloadId = data.connection.target.workloadId || '';
    this.versionId = data.connection.target.versionId || '';
  }
}
