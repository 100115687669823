import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/lang/index';
import vuetify from '../plugins/vuetify';

Vue.use(VueI18n);

let storedLang = localStorage?.getItem('lang');
const languages = vuetify.userPreset.lang.locales;

if (!storedLang && navigator?.language.split('-')[0] in languages) {
  storedLang = navigator?.language.split('-')[0] || 'en';
}

const i18n = new VueI18n({
  locale: storedLang || 'en',
  fallbackLocale: 'en',
  messages,
});

export default i18n;
