<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25">
    <defs>
      <linearGradient :id="id" x1="50%" x2="50%" y1="0%" y2="99.665%">
        <stop offset="0%" stop-color="#FF1E00" />
        <stop offset="100%" stop-color="#FF7E01" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <circle :fill="`url(#${id})`" cx="10" cy="10" r="10" />
      <path
        stroke="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
        d="M10 10l3-3-3 3-3-3 3 3zm0 0l-3 3 3-3 3 3-3-3z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OfflineIcon',
  data() {
    return {
      id: 1,
    };
  },
  mounted() {
    this.id = Math.random();
  },
};
</script>

<style scoped>
path {
  stroke-width: 2px;
}
</style>
