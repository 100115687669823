import { set, toggle } from '@/utils/vuex';

export default {
  state: {
    drawer: false,
    sidebarBackgroundColor: 'rgba(27, 27, 27, 0.74)',
  },
  mutations: {
    SET_DRAWER: set('drawer'),
    TOGGLE_DRAWER: toggle('drawer'),
  },
  actions: {
    async set_drawer({ commit }, param) {
      commit('SET_DRAWER', param);
    },
  },
};
