// Lib imports
import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
// Routes
import langs from '@/lang';
import i18n from '@/i18n';
// eslint-disable-next-line import/no-cycle
import mqtt from '@/plugins/mqtt';
import paths from './paths';

function route({ path, view, name, meta, props }) {
  return {
    name: name || view,
    path,
    meta,
    component: async () => import(`@/views/${view}.vue`),
    props,
  };
}

const isRefreshed = (from) => !from.name;

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: paths.map((path) => route(path)),
});

export const beforeEach = async (to, from, next) => {
  store.dispatch('workloads/setAddEditWorkloadVersionRoute', false);
  if (from.name === 'Add edit workload version') {
    store.dispatch('workloads/setAddEditWorkloadVersionRoute', true);
  }
  if (langs[to.query.lang]) {
    i18n.locale = to.query.lang;
    i18n.fallbackLocale = null;
    localStorage.setItem('lang', i18n.locale);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isRefreshed(from)) {
      try {
        await store.dispatch('auth/is_user_logged');
        if (store.getters['auth/isLoggedIn']()) {
          mqtt.connect();
          next();
        }
      } catch (e) {
        Vue.prototype.$log.debug(e);
      }
    }
    if (
      to.path.includes('resetPassword') ||
      to.path.includes('forgot-password') ||
      to.path.includes('activate') ||
      !store.getters['auth/isLoggedIn']()
    ) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
    next();
  } else {
    next();
  }
};
router.beforeEach(async (to, from, next) => beforeEach(to, from, next));

export default router;
