import store from '@/store';

export default {
  actions: {
    mqtt_cloud(_, payload) {
      const type = store.getters['node-update-log/getType']();
      if (payload.name === 'all-admins-bom-campaign-updated') {
        if (type === 'update_node') {
          store.dispatch('node-update-log/updated_campaign', payload);
          return;
        }
        store.dispatch('deploy-log/updated_campaign', payload);
        store.dispatch('deploy-details/updated_campaign', payload);
      }
      if (payload.name === 'all-admins-bom-campaign-updated-finished') {
        if (type === 'update_node') {
          store.dispatch('node-update-log/updated_finished_campaign', payload);
          return;
        }
        store.dispatch('deploy-log/updated_finished_campaign', payload);
        store.dispatch('deploy-details/updated_finished_campaign', payload);
      }
      if (payload.name === 'all-admins-bom-task-updated') {
        if (type === 'update_node') {
          store.dispatch('node-update-details/updated_task', payload);
          return;
        }
        store.dispatch('deploy-details/updated_task', payload);
      }
    },
  },
};
