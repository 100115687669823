/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import store from '@/store';

const defaultToastParams = {
  text: '',
  showClose: true,
  color: '',
};
const defaultMessageBoxParams = {
  title: '',
  text: '',
  list: [],
  noClose: false,
  redirect: false,
};

export default {
  state: {
    loadingBar: false,
    toast: false,
    toastParams: defaultToastParams,
    messageBox: false,
    messageBoxParams: defaultMessageBoxParams,
    confirmDialog: false,
    noConnectionFlag: false,
    confirmDialogParams: {
      title: '',
      subTitle: '',
      dontAskMeAgain: false,
      callback: Function,
      cancelCallback: Function,
      dontAskMeAgainCallback: Function,
    },
    progressBarDialogParams: {
      callback: Function,
    },
    errorInfoDialog: false,
    errorInfoDialogParams: {
      title: '',
      defautlMsg: '',
      troubleshooting: '',
      timeOfError: '',
      callback: Function,
      cancelCallback: Function,
    },
    progressBarDialog: false,
    infoDialog: false,
    infoDialogParams: {
      title: '',
      subTitle: '',
      callback: Function,
      cancelCallback: Function,
    },
    deployInfoDialog: false,
    deployInfoDialogParams: {
      title: '',
      titleParams: {},
      subTitle: '',
      callback: Function,
    },
    apiPermissionConfirmDialog: false,
    apiPermissionConfirmDialogParams: {
      title: '',
      msg: '',
      confirmCallback: Function,
      cancelCallback: Function,
    },
    applyWorkloadConfigurationDialog: false,
    applyWorkloadConfigurationDialogParams: {
      title: '',
    },
    notificationDialog: false,
    notificationDialogParams: {
      title: '',
      subTitle: '',
      callback: Function,
      cancelCallback: Function,
    },
    loaderCircular: false,
  },
  mutations: {
    SHOW_LOADING_BAR: (state) => {
      state.loadingBar = true;
    },
    SHOW_TOAST: (state, params) => {
      if (state.toast) clearTimeout(state.toast);
      state.toastParams = { ...defaultToastParams, ...params };
      state.toast = setTimeout(() => {
        state.toast = false;
      }, 10000);
    },
    SHOW_PERMANENT_TOAST: (state, flag) => {
      const params = {
        text: 'errorMessages.no_connection_error',
        color: 'red',
        showClose: true,
      };
      if (state.toast) clearTimeout(state.toast);
      state.toastParams = { ...defaultToastParams, ...params };
      state.toast = flag;
    },
    SHOW_MESSAGE_BOX: (state, params) => {
      state.messageBoxParams = Object.assign(defaultMessageBoxParams, params);
      state.messageBox = true;
    },
    CLOSE_LOADING_BAR: (state) => {
      state.loadingBar = false;
    },
    CLOSE_TOAST: (state) => {
      if (state.toast) clearTimeout(state.toast);
      state.toast = false;
    },
    CLOSE_MESSAGE_BOX: (state) => {
      state.messageBox = false;
      state.messageBoxParams = defaultMessageBoxParams;
    },
    CLOSE_CONFIRM: (state) => {
      state.confirmDialog = false;
    },
    SHOW_CONFIRM: (state, params) => {
      state.confirmDialog = true;
      state.confirmDialogParams = params;
    },
    SHOW_PROGRESS_BAR: (state, params) => {
      state.progressBarDialog = true;
      state.progressBarDialogParams = params;
    },
    CLOSE_PROGRESS_BAR: (state) => {
      state.progressBarDialog = false;
    },
    CLOSE_INFO: (state) => {
      state.infoDialog = false;
    },
    CLOSE_DEPLOY_INFO: (state) => {
      state.deployInfoDialog = false;
    },
    SHOW_INFO: (state, params) => {
      state.infoDialog = true;
      state.infoDialogParams = params;
    },
    SHOW_DEPLOY_INFO: (state, params) => {
      state.deployInfoDialog = true;
      state.deployInfoDialogParams = params;
    },
    SHOW_ERROR_INFO: (state, params) => {
      state.errorInfoDialog = true;
      state.errorInfoDialogParams = params;
    },
    CLOSE_ERROR_INFO: (state) => {
      state.errorInfoDialog = false;
    },
    NO_CONNECTION_FLAG: (state, flag) => {
      state.noConnectionFlag = flag;
    },
    CLOSE_API_PERMISSION_CONFIRM: (state) => {
      state.apiPermissionConfirmDialog = false;
    },
    SHOW_API_PERMISSION_CONFIRM: (state, params) => {
      state.apiPermissionConfirmDialog = true;
      state.apiPermissionConfirmDialogParams = params;
    },
    CLOSE_APPLY_WORKLOAD_CONFIGURATION: (state) => {
      state.applyWorkloadConfigurationDialog = false;
    },
    SHOW_APPLY_WORKLOAD_CONFIGURATION: (state, params) => {
      state.applyWorkloadConfigurationDialog = true;
      state.applyWorkloadConfigurationDialogParams = params;
    },
    CLOSE_NOTIFICATION: (state) => {
      state.notificationDialog = false;
    },
    SHOW_NOTIFICATION: (state, params) => {
      state.notificationDialog = true;
      state.notificationDialogParams = params;
    },
    SHOW_LOADER_CIRCULAR: (state) => {
      state.loaderCircular = true;
    },
    CLOSE_LOADER_CIRCULAR: (state) => {
      state.loaderCircular = false;
    },
  },
  getters: {
    getLoadingBarState: (state) => () => state.loadingBar,
    getToastState: (state) => () => state.toast,
    getToastParams: (state) => () => state.toastParams,
    getMessageBoxState: (state) => () => state.messageBox,
    getMessageBoxParams: (state) => () => state.messageBoxParams,
    getConfirmDialogState: (state) => () => state.confirmDialog,
    getConfirmDialogParams: (state) => () => state.confirmDialogParams,
    getInfoDialogState: (state) => () => state.infoDialog,
    getProgressBarDialogState: (state) => () => state.progressBarDialog,
    getProgressBarDialogParams: (state) => () => state.progressBarDialogParams,
    getErrorInfoDialogState: (state) => () => state.errorInfoDialog,
    getInfoDialogParams: (state) => () => state.infoDialogParams,
    getErrorInfoDialogParams: (state) => () => state.errorInfoDialogParams,
    getApiPermissionConfirmDialogState: (state) => () => state.apiPermissionConfirmDialog,
    getApiPermissionConfirmDialogParams: (state) => () => state.apiPermissionConfirmDialogParams,
    getApplyWorkloadConfigurationDialogState: (state) => () => state.applyWorkloadConfigurationDialog,
    getApplyWorkloadConfigurationDialogParams: (state) => () => state.applyWorkloadConfigurationDialogParams,

    getNotificationDialogState: (state) => () => state.notificationDialog,
    getNotificationDialogParams: (state) => () => state.notificationDialogParams,
    getNoConnectionFlag: (state) => () => state.noConnectionFlag,
    getDeployInfoDialogState: (state) => state.deployInfoDialog,
    getDeployInfoDialogParams: (state) => state.deployInfoDialogParams,
    getLoaderCircularState: (state) => state.loaderCircular,
  },
  actions: {
    show_loading_bar({ commit }) {
      commit('SHOW_LOADING_BAR');
    },

    show_toast({ commit }) {
      commit('SHOW_TOAST', { text: 'toast.text', color: 'success' });
    },

    show_custom_toast({ commit }, params) {
      commit('SHOW_TOAST', params);
    },

    show_permanent_toast({ commit }, flag) {
      commit('SHOW_PERMANENT_TOAST', flag);
    },

    show_message_box({ commit }, params) {
      commit('SHOW_MESSAGE_BOX', params);
    },

    show_permanent_message({ commit }) {
      commit('SHOW_MESSAGE_BOX', {
        title: 'interfaceDialog.title',
        text: 'interfaceDialog.text',
        noClose: true,
      });
    },

    show_error_dialog({ commit }) {
      commit('SHOW_MESSAGE_BOX', { title: 'errorDialog.title', text: 'errorDialog.text' });
    },

    close_loading_bar({ commit }) {
      commit('CLOSE_LOADING_BAR');
    },

    close_toast({ commit }) {
      commit('CLOSE_TOAST');
    },

    change_no_connection_flag({ commit }, flag) {
      commit('NO_CONNECTION_FLAG', flag);
    },

    close_message_box({ state, commit }) {
      commit('CLOSE_MESSAGE_BOX');
      if (state.messageBoxParams.redirect) store.dispatch('network-interface/redirect');
    },

    show_progress_bar_dialog({ commit }, params) {
      commit('SHOW_PROGRESS_BAR', params);
    },

    close_progress_bar({ commit }) {
      commit('CLOSE_PROGRESS_BAR');
    },

    show_confirm_dialog({ commit }, params) {
      commit('SHOW_CONFIRM', params);
    },

    close_confirm({ commit }) {
      commit('CLOSE_CONFIRM');
    },

    show_info_dialog({ commit }, params) {
      commit('SHOW_INFO', params);
    },

    show_deploy_info_dialog({ commit }, params) {
      commit('SHOW_DEPLOY_INFO', params);
    },

    show_error_info_dialog({ commit }, params) {
      commit('SHOW_ERROR_INFO', params);
    },

    close_error_info_dialog({ commit }) {
      commit('CLOSE_ERROR_INFO');
    },

    close_info({ commit }) {
      commit('CLOSE_INFO');
    },

    close_deploy_info({ commit }) {
      commit('CLOSE_DEPLOY_INFO');
    },

    show_api_permission_confirm_dialog({ commit }, params) {
      commit('SHOW_API_PERMISSION_CONFIRM', params);
    },

    close_api_permission_confirm({ commit }) {
      commit('CLOSE_API_PERMISSION_CONFIRM');
    },

    show_apply_workload_configuration_dialog({ commit }, params) {
      commit('SHOW_APPLY_WORKLOAD_CONFIGURATION', params);
    },

    close_apply_workload_configuration({ commit }) {
      commit('CLOSE_APPLY_WORKLOAD_CONFIGURATION');
    },

    show_notification_dialog({ commit }, params) {
      commit('SHOW_NOTIFICATION', params);
    },
    close_notification({ commit }) {
      commit('CLOSE_NOTIFICATION');
    },

    show_loader_circular({ commit }) {
      commit('SHOW_LOADER_CIRCULAR');
    },
    close_loader_circular({ commit }) {
      commit('CLOSE_LOADER_CIRCULAR');
    },
  },
};
