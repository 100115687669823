/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import Vue from 'vue';
import BackupModel from '@/model/backup/backup.model';
import { BACKUP_STATUSES } from '@/model/node/sub-models/deployed-workload.model';
import store from '@/store';
import { BackupsApiService } from '../../services/api';

export default {
  state: {
    vmBackupList: [],
    deviceId: '',
    nodeSerialNumber: '',
  },
  mutations: {
    SET_BACKUPS(state, backups) {
      state.vmBackupList = backups.map((backup) => new BackupModel(backup));
    },
    SET_DEVICE_ID(state, id) {
      state.deviceId = id;
    },
    SET_SERIAL_NUMBER(state, serialNumber) {
      state.nodeSerialNumber = serialNumber;
    },
    SET_BACKUPS_HISTORY_ITEM(state, data) {
      const found = state.vmBackupList.find((el) => el.name === data.name);
      if (!found) {
        state.vmBackupList.unshift(new BackupModel(data));
        return;
      }
      if (data.error) {
        found.error = data.error;
        found.timeOfError = data.finished;
        return;
      }
      found.started = data.started;
      found.status = data.status;
      found.finished = data.status !== BACKUP_STATUSES.COMPLETED ? '' : data.finished;
      found.progress = data.progress || 0;
    },
  },
  getters: {
    list: (state) => state.vmBackupList,
    getDeviceId: (state) => state.deviceId,
  },
  actions: {
    async fetch({ commit }, deviceId) {
      try {
        // If either deviceId or serialNumber does not exist axios request should not be sent
        if (!deviceId.deviceId || !deviceId.serialNumber) {
          return;
        }
        let backups = await BackupsApiService.fetchBackups(deviceId);
        backups.sort((a, b) => (a.started > b.started ? -1 : b.started > a.started ? 1 : 0));
        backups = backups.map((el) => {
          el.started = new Date(el.started).toLocaleString('en-GB');
          el.finished = new Date(el.finished).toLocaleString('en-GB');
          if (el.error) {
            el.timeOfError = el.finished;
          }
          if (el.status !== BACKUP_STATUSES.COMPLETED) {
            el.finished = '';
          }
          return el;
        });
        commit('SET_BACKUPS', backups);
      } catch (e) {
        commit('SET_BACKUPS', []);
        throw e;
      }
    },
    async create_backup({ commit }, params) {
      try {
        store.dispatch('utils/_api_request_handler/show_loading_bar');
        await BackupsApiService.createBackup(params);
        store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'workloadManagement.creatingABackupInProgress',
          color: 'success',
          showClose: true,
        });
      } catch (e) {
        Vue.prototype.$log.debug(e);
      } finally {
        store.dispatch('utils/_api_request_handler/close_loading_bar');
      }
    },
    async retry_deploy({ state }, params) {
      try {
        store.dispatch('utils/_api_request_handler/show_loading_bar');
        if (params?.reqObj?.workloadId && params?.reqObj?.versionId) {
          params.reqObj.workloadId = params.reqObj.workloadId?.split('-')[0];
          params.reqObj.versionId = params.reqObj.versionId?.split('-')[0];
        }
        await BackupsApiService.retryBackup({
          serialNumber: state.nodeSerialNumber,
          deviceId: params.deviceId,
          reqObj: params.reqObj,
        });
        store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'workloadManagement.creatingABackupInProgress',
          color: 'success',
          showClose: true,
        });
      } catch (err) {
        Vue.prototype.$log.debug(err);
      } finally {
        store.dispatch('utils/_api_request_handler/close_loading_bar');
      }
    },
    set_device_id({ commit }, id) {
      commit('SET_DEVICE_ID', id);
    },
    set_serial_number({ commit }, serialNumber) {
      commit('SET_SERIAL_NUMBER', serialNumber);
    },
    mqtt_backups({ commit, state }, data) {
      const { deviceId, backup } = data.params;
      if (state.deviceId !== deviceId) {
        return;
      }
      backup.started = new Date(backup.started).toLocaleString('en-GB');
      backup.finished = backup.finished ? new Date(backup.finished).toLocaleString('en-GB') : '';

      commit('SET_BACKUPS_HISTORY_ITEM', backup);
    },
  },
};
