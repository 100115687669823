/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

// import router from '@/router'
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import shared from '@/helpers/shared';
import config from '../../config/default.json';

axios.defaults.baseURL = config.BASE_URL;

/*
  Set cloud auth header from local storage
   */
function setSessionHeader(request) {
  const sessionFromLS = localStorage.getItem('session');
  if (sessionFromLS) {
    const session = JSON.parse(sessionFromLS);
    if (!request.headers) {
      // eslint-disable-next-line no-param-reassign
      request.headers = {};
    }

    // eslint-disable-next-line no-param-reassign
    request.headers.sessionId = session.sessionId;
  }
}

axios.interceptors.request.use(
  (request) => {
    if (request && request.authRequired) {
      setSessionHeader(request);
    }
    return request;
  },
  (error) => {
    Promise.reject(error);
  },
);

axios.interceptors.response.use(
  // David's changes(warning messages)
  (response) => {
    if (response?.config?.url?.indexOf('nerve/dna') !== -1 && response.headers?.['content-disposition']) {
      // eslint-disable-next-line global-require
      const { useStoreDna } = require('../store/modules/pinia/dna');
      const storeDna = useStoreDna();
      storeDna.setFileName(response.headers['content-disposition'].split('filename=')[1]);
    }
    if (store.getters['utils/_api_request_handler/getNoConnectionFlag']()) {
      store.dispatch('utils/_api_request_handler/change_no_connection_flag', false);
    }
    return response;
  },
  async (error) => {
    if (error.config && error.config.ignoreInterceptor) {
      // Check if the error is associated with a request that has ignoreInterceptor
      // If it does, throw error without showing toast messages
      throw error;
    }
    if (error.response && error.response.data && error.response.data.error) {
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: error.response.data.error,
        color: 'red',
        showClose: true,
      });
      throw error;
    }
    const txt =
      error.response && error.response.data && error.response.data[0] && error.response.data[0].errorCode
        ? `errorMessages.${error.response.data[0].errorCode}`
        : 'errorMessages.nerve_unexp_server_001';
    // Redis
    if (error.response && error.response.data[0]?.errorCode === 'svc_0001') {
      await store.dispatch('auth/is_services_unavailable', {
        service: 'Redis',
        isUnavailable: true,
      });
    }
    // MongoDB
    if (error.response && error.response.data[0]?.errorCode === 'svc_0002') {
      await store.dispatch('auth/is_services_unavailable', {
        service: 'MongoDB',
        isUnavailable: true,
      });
    }
    // RabbitMQ
    if (error.response && error.response.data[0]?.errorCode === 'svc_0005') {
      await store.dispatch('auth/is_services_unavailable', {
        service: 'RabbitMQ',
        isUnavailable: true,
      });
    }

    // Don't show toast if errors are connected to docker-compose file validation
    if (
      error.request &&
      error.request.responseURL.includes('/nerve/v3/workloads/compose') &&
      error.response &&
      (error.response.data[0]?.errorCode === 'nerve_workload_166' ||
        error.response.data[0]?.errorCode === 'nerve_workload_195' ||
        error.response.data[0]?.errorCode === 'nerve_workload_201' ||
        error.response.data[0]?.errorCode === 'nerve_workload_202' ||
        error.response.data[0]?.errorCode === 'nerve_workload_203' ||
        error.response.data[0]?.errorCode === 'nerve_workload_204' ||
        error.response.data[0]?.errorCode === 'nerve_workload_205')
    ) {
      throw error;
    }

    // Brute force protection
    if (error?.response?.data[0]?.errorCode === 'nerve_auth_001') {
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: txt,
        textParameters: shared.timeFormatHourMinSeconds(error.response.data[0].content?.retryAfter || 1800),
        color: 'red',
        showClose: true,
      });
      throw error;
    }

    // Compose with multiple services not supported on node
    if (error?.response?.data[0]?.errorCode === 'nerve_workload_218') {
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: txt,
        textParameters: error.response.data[0].content?.serialNumbers,
        color: 'red',
        showClose: true,
      });
      throw error;
    }

    if (error?.response?.status === 403) {
      // Prevent deadloop in case when logout returns 403
      if (error?.request?.responseURL.includes('auth/logout')) {
        return;
      }
      store.dispatch('auth/logout');
      router.push({ name: 'Login' }).catch(() => {});
    }
    if (error.response && error.response.status !== 401 && error.response.status !== 403) {
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: txt,
        color: 'red',
        showClose: true,
      });
    }
    if (!error.response && error.message) {
      if (!store.getters['utils/_api_request_handler/getNoConnectionFlag']()) {
        if (error.message.toLowerCase().indexOf('network error') !== -1) {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'errorMessages.network_error',
            color: 'red',
            showClose: true,
          });
        }
        store.dispatch('utils/_api_request_handler/change_no_connection_flag', true);
      }
    }

    throw error;
  },
);

export default axios;
