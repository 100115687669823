/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2023. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

export default class ComposeFileModel {
  /**
   * Creates an instance of ComposeFileModel.
   * @param {Object} data - ComposeFileModel
   * @class ComposeFileModel
   * defined for each file individually.
   * @property {string} name - name of the service
   * @property {string} origin - Docker file path
   * @property {string} originalName - Docker file option
   * @property {String} source - auth credentials
   * @property {String} status - auth credentials
   * @property {String} type - auth credentials
   * @property {String} _id - auth credentials
   * @property {String} username - auth credentials
   * @property {String} password - auth credentials
   */
  constructor(data = {}) {
    this.name = data.name || '';
    this.origin = data.origin || '';
    this.originalName = data.originalName || '';
    this.source = data.source || '';
    this.status = data.status || '';
    this.type = data.type || '';
    this._id = data._id || '';
    this.username = data.username || '';
    this.password = data.password || '';
  }
}
