/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2023. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import BaseApiService from './base.api-service';

const PATH = '/nerve/dna';

class DnaApiService extends BaseApiService {
  getTargetConfiguration(param) {
    const options = {
      cancelToken: param.token,
    };
    return this.get(`${PATH}/${param.serialNumber}/status`, options, { authRequired: true });
  }

  downloadCurrentConfiguration(serialNumber) {
    const options = {
      headers: {
        Accept: 'application/zip; application/octet-stream',
      },
      responseType: 'blob',
    };
    return this.get(`${PATH}/${serialNumber}/current`, options, { authRequired: true });
  }

  downloadTargetConfiguration(serialNumber) {
    const options = {
      headers: {
        Accept: 'application/zip; application/octet-stream',
      },
      responseType: 'blob',
    };
    return this.get(`${PATH}/${serialNumber}/target`, options, { authRequired: true });
  }

  reApplyTargetConfiguration(serialNumber) {
    return this.put(`${PATH}/${serialNumber}/target/re-apply`, {}, { authRequired: true });
  }

  applyConfiguration({ formData, serialNumber }) {
    const options = {
      headers: { 'Content-Type': undefined },
    };
    return this.put(`${PATH}/${serialNumber}/target`, formData, options, { authRequired: true });
  }

  cancelApplyConfiguration(serialNumber) {
    return this.patch(`${PATH}/${serialNumber}/target/cancel`, {}, { authRequired: true });
  }
}

export default new DnaApiService();
