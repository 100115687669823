/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2023. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import { defineStore } from 'pinia';
import axios from 'axios';
import { DnaApiService } from '@/services/api';
import DnaModel from '@/model/dna/dna.model';
import store from '@/store';

// eslint-disable-next-line import/prefer-default-export
export const useStoreDna = defineStore('storeDna', {
  state: () => ({
    targetConfiguration: {},
    fileName: '',
  }),

  getters: {
    getTargetConfiguration: (state) => new DnaModel(state.targetConfiguration),
  },

  actions: {
    async fetchTargetConfiguration(serialNumber) {
      try {
        const source = axios.CancelToken.source();
        store.dispatch('node-tree/set_cancel_token_dna_status', source);
        this.targetConfiguration = await DnaApiService.getTargetConfiguration({
          serialNumber,
          token: source.token,
        });
      } catch (e) {
        this.targetConfiguration = {};
      }
    },
    async fetchTargetConfigurationEmpty() {
      this.targetConfiguration = {};
    },
    async downloadCurrentConfiguration(serialNumber) {
      const singleReport = await DnaApiService.downloadCurrentConfiguration(serialNumber);
      const currentFileName = this.fileName || 'current_conf.zip';
      this.downloadFileInBrowser(singleReport, currentFileName);
    },

    async downloadTargetConfiguration(serialNumber) {
      const singleReport = await DnaApiService.downloadTargetConfiguration(serialNumber);
      const targetFileName = this.fileName || 'target_conf.zip';
      this.downloadFileInBrowser(singleReport, targetFileName);
    },

    downloadFileInBrowser(singleReport, fileName) {
      const url = window.URL.createObjectURL(new Blob([singleReport]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async reApplyTargetConfiguration(serialNumber) {
      await DnaApiService.reApplyTargetConfiguration(serialNumber);
    },

    async applyConfiguration({ serialNumber, formData }) {
      await DnaApiService.applyConfiguration({ serialNumber, formData });
    },

    async cancelApplyConfiguration(serialNumber) {
      await DnaApiService.cancelApplyConfiguration(serialNumber);
    },

    mqttDna(payload) {
      this.targetConfiguration = payload;
    },

    setFileName(fileName) {
      this.fileName = fileName;
    },
  },
});
