/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import WorkloadPropertiesModel from '@/model/workloads/workload-properties.model';
import RemoteConnectionModel from '@/model/node/remoteConnection.model';
import LabelBasicModel from '../label/label.basic.model';

export default class WorkloadVersionModel {
  /**
   * Creates an instance of WorkloadVersionModel.
   * @param {Array} data - WorkloadVersionModel list
   * @class WorkloadVersionModel
   * @property {string} _id - Version id
   * @property {string} name - Version name
   * @property {string} releaseName - Version release name
   * @property {string} created - Version created
   * @property {object} workloadProperties - Workload properties
   * @property {boolean} restartOnConfigurationUpdate - Restart on configuration update
   * @property {boolean} delete - Is deleted version
   * @property {boolean} isDeployable - Is deployable version
   * @property {array} remoteConnections - List of remote connections
   * @property {boolean} firstVolumeAsConfigurationStorage - First volume as configuration storage
   * @property {string} dockerFilePath - Docker file path
   * @property {string} dockerFileOption - Docker file option
   * @property {array} files - Uploaded files
   * @property {string} updatedAt - Version updated
   * @property {string} type - Workload type
   * @property {string} cloneOf - Id from cloned version
   * @property {string} hash - Version hash
   */
  constructor(data = {}) {
    this.id = data.version._id;
    this.name = data.version.name || '';
    this.releaseName = data.version.releaseName || '';
    this._id = data.version._id || data.version.id;
    this.created = data.version.createdAt
      ? new Date(data.version.createdAt).toLocaleDateString('en-GB') || ''
      : data.version.created;
    this.selectors = data.version.selectors
      ? data.version.selectors.map((selector) => new LabelBasicModel(selector))
      : [];
    this.capabilities = data.version.capabilities || [];
    this.released = data.version.released || false;
    this.isDownloading = data.version.isDownloading || false;
    this.errorMessage = data.version.errorMessage || '';
    if (data.type === 'vm' || data.type === 'docker') {
      this.workloadProperties =
        new WorkloadPropertiesModel({
          workloadProperties: data.version.workloadProperties || {},
          type: data.type,
        }) || {};
    }
    this.restartOnConfigurationUpdate = data.version.restartOnConfigurationUpdate || false;
    this.deleted = data.version.deleted || false;
    this.isDeployable = data.version.isDeployable || false;
    this.remoteConnections = data.version.remoteConnections
      ? data.version.remoteConnections.map((rc) => new RemoteConnectionModel(rc))
      : [];
    this.dockerFilePath = data.version.dockerFilePath || '';
    this.dockerFileOption = data.version.dockerFileOption || 'path';
    this.files = data.version.files || [];
    this.updatedAt = data.version.updatedAt || '';
    this.cloneOf = data.version.cloneOf;
    this.hash = data.version.hash || '';
  }
}
