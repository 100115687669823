/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2019. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
export default class UsersModel {
  /**
   * Creates an instance of UsersModel.
   * @param {Array} data - UsersModel list
   * @class UsersModel
   * @property {string} searchBase - Search
   * @property {string} filter - Filter
   * @property {string} firstName - LDAP attribute that holds user first name
   * @property {string} lastName - LDAP attribute that holds user last name
   * @property {string} email - Email attribute
   * @property {string} username - LDAP attribute that holds user email

   */
  constructor(data = {}) {
    this.searchBase = data.searchBase || '';
    this.filter = data.filter || '';
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.email = data.email || '';
    this.username = data.username || '';
  }
}
