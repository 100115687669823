/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import { NodeUpdateLogApiService } from '@/services/api';
import NodeUpdateLogModel from '@/model/node-update-log/node-update-log.model';
import store from '@/store';

export default {
  state: {
    nodeUpdateLog: [],
    totalLogs: 0,
    type: '',
    delete: false,
  },
  mutations: {
    SET_TYPE(state, type) {
      state.type = type;
    },
    SET_NODE_UPDATE_LOG(state, list) {
      state.nodeUpdateLog = list.map((log) => new NodeUpdateLogModel(log));
    },
    SET_TOTAL_LOGS(state, count) {
      state.totalLogs = count;
    },
    REMOVE_NODE_UPDATE(state, nodeUpdateLog) {
      state.nodeUpdateLog.splice(
        state.nodeUpdateLog.findIndex((log) => log.id === nodeUpdateLog.id),
        1,
      );
    },
    UPDATE_LOG(state, payload) {
      state.nodeUpdateLog.map((log) => {
        if (log.id === payload.id) {
          log.progress = payload.progress.toFixed(2);
          log.status = payload.status;
          log.started = new Date(payload.timeOfOperationStart).toLocaleString('en-GB');
        }
      });
    },
    FINISHED_LOG(state, payload) {
      state.nodeUpdateLog.map((log) => {
        if (log.id === payload.id) {
          log.progress = payload.progress.toFixed(2);
          const updateFailed = payload.status === 'Error' ? 'Failed' : payload.status === 'Completed';
          log.status = updateFailed ? 'Completed' : payload.status;
          log.finished = new Date(payload.timeOfOperationFinish).toLocaleString('en-GB');
        }
      });
    },
  },
  getters: {
    getNodeUpdateLog: (state) => state.nodeUpdateLog,
    getTotalLogs: (state) => state.totalLogs,
    getProgressStatus: (state) => () => state.progressStatus,
    getType: (state) => () => state.type,
  },
  actions: {
    async fetch({ state, commit }, data) {
      if (state.delete && state.nodeUpdateLog.length === 0 && data.page > 1) {
        data.page -= 1;
      }
      const params = {
        limit: data.itemsPerPage || 10,
        page: data.page || 1,
        filterBy: { operation_name: data.search },
        contentType: 'node_update',
      };
      const list = await NodeUpdateLogApiService.nodeUpdateLog({ params });
      commit('SET_NODE_UPDATE_LOG', list.data);
      commit('SET_TOTAL_LOGS', list.count);
    },
    async delete_log({ state, commit }, param) {
      state.delete = true;
      await NodeUpdateLogApiService.deleteNodeUpdate(param);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'nodeUpdateLog.successfullyRemovedNodeUpdate',
        color: 'success',
        showClose: true,
      });
      commit('REMOVE_NODE_UPDATE', param);
    },
    setType({ commit }, param) {
      commit('SET_TYPE', param.type);
    },
    updated_campaign({ commit }, payload) {
      commit('UPDATE_LOG', payload.params);
    },
    updated_finished_campaign({ commit }, payload) {
      commit('FINISHED_LOG', payload.params.data);
    },
  },
};
