/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import semver from 'semver';

export default {
  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  },

  isObject(entity) {
    return typeof entity === 'object' && entity !== null;
  },

  dateFormatByLanguage(value) {
    // TODO Change format of date by selected language
    return new Date(value).getDate()
      ? new Date(value).toLocaleDateString(['en-EN'], {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      : value;
  },

  timeFormatHourMinSeconds(duration) {
    // Hours, minutes and seconds
    const hrs = Math.floor(duration / 3600);
    const mins = Math.floor((duration % 3600) / 60);
    const secs = Math.floor(duration) % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = '';

    if (hrs > 0) {
      ret += `${hrs}h:${mins < 10 ? '0' : ''}`;
    }

    ret += `${mins}min:${secs < 10 ? '0' : ''}`;
    ret += `${secs}s`;
    return ret;
  },

  versionWithoutPrereleaseTags(version) {
    if (!version || !semver.valid(version)) {
      return null;
    }
    const prereleaseTags = semver.prerelease(version);
    if (!prereleaseTags) {
      return version;
    }
    const major = semver.major(version);
    const minor = semver.minor(version);
    const patch = semver.patch(version);

    return `${major}.${minor}.${patch}`;
  },
  isFullVersionGreaterThan(version, referenceVersion) {
    const nodeVersion = this.versionWithoutPrereleaseTags(version);
    return nodeVersion && semver.gt(version, referenceVersion);
  },
  isNoPrerelesedVersionGreaterThanOrEqualTo(version, referenceVersion) {
    const nodeVersion = this.versionWithoutPrereleaseTags(version);
    return nodeVersion && semver.gte(nodeVersion, referenceVersion);
  },
  /**
   * @description If tag is not defined, add latest
   * @param {string} imageName The name of the docker image
   */
  addTagLatestIfMissing(imageName) {
    const splitByColon = imageName.split(':');
    return splitByColon.length === 1 ? `${imageName}:latest` : imageName;
  },

  compareTwoDockerImageNamesAreEqual(name1, name2) {
    if (!name1 || !name2) {
      return false;
    }
    return Boolean(this.addTagLatestIfMissing(name1) === this.addTagLatestIfMissing(name2));
  },
};
