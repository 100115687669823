/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import { UsageReportsApiService } from '@/services/api';
import UsageReportModel from '@/model/usage-reports/usage-report.model';

export default {
  state: {
    usageReports: [],
    config: {
      notifyTTTech: false,
    },
  },
  mutations: {
    SET_USAGE_REPORTS(state, usageReports) {
      state.usageReports = usageReports.data.map((label) => new UsageReportModel(label));
    },
    SET_CONFIGURATION(state, config) {
      state.config = config;
    },
  },
  getters: {
    list: (state) => state.usageReports,
    config: (state) => state.config,
  },
  actions: {
    async fetch({ commit }, params = {}) {
      commit('SET_USAGE_REPORTS', await UsageReportsApiService.fetchUsageReports({ params }));
    },
    async update_config(_, config) {
      await UsageReportsApiService.updateConfig(config);
    },
    async get_config({ commit }) {
      commit('SET_CONFIGURATION', await UsageReportsApiService.getConfig());
    },
    async get_single(_, { month, year }) {
      const singleReport = await UsageReportsApiService.getSingle({ params: { month, year } });
      window.open(singleReport.relativePath);
    },
  },
};
