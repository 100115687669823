/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
export default class RoleModel {
  /**
   * Creates an instance of RoleModel.
   * @param {Object} role - Role object from the API
   * @class RoleModel
   * @property {string} _id - Role id
   * @property {string} name - Role name
   * @property {string} description - Role description
   * @property {array} permissions - Role permisions
   * @property {array} users - Ids of users which have this role assigned
   * @property {string} type - Role type
   * @property {string} ldap - Ldap
   */
  constructor(role = {}) {
    this.id = role._id || '';
    this.name = role.name || '';
    this.description = role.description || '';
    this.defaultRole = role.defaultRole || false;
    this.permissions = role.permissions || [];
    this.users = role.users || [];
    this.prettyRoleName = role;
    this.type = role.type || '';
    this.ldap = role.ldap || {};
  }

  set prettyRoleName(role) {
    if (!role.ldap) {
      this._prettyRoleName = role.name || '';
      return;
    }
    const firstIndexOfLdap = role.name.indexOf(role.ldap.fileName);
    if (firstIndexOfLdap === -1) {
      this._prettyRoleName = role.name;
      return;
    }
    // The -2 is to remove - from the name
    // Admins - configName
    this._prettyRoleName = role.name.substring(0, firstIndexOfLdap - 2).trim();
  }

  get prettyRoleName() {
    return this._prettyRoleName;
  }
}
