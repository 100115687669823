/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import BaseApiService from './base.api-service';

const PATH = '/nerve/notifications';

class NotificationApiService extends BaseApiService {
  fetchNotifications(params) {
    return this.get(PATH, params, { authRequired: true });
  }

  createOrUpdateNotification(formData, id) {
    const options = {
      // baseURL: '/',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return id
      ? this.put(`${PATH}/${id}`, formData, options, { authRequired: true })
      : this.post(PATH, formData, options, { authRequired: true });
  }

  getNotification(id) {
    return this.get(`${PATH}/${id}`, { authRequired: true });
  }

  remove(id) {
    return this.delete(`${PATH}/${id}`, { authRequired: true });
  }

  getActiveNotificationAfterLogin() {
    return this.get(`${PATH}/active`, { authRequired: true });
  }

  getActiveNotificationBeforeLogin() {
    return this.get(`${PATH}/activeNoAuth`);
  }
}

export default new NotificationApiService();
