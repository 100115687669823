/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import WorkloadsApiService from '@/services/api/workloads.api-service';
import WorkloadDeployUpdateModel from '@/model/workload-deploy-update/workload-deploy-update.model';
import { NodesApiService } from '@/services/api';

export default {
  state: {
    versions: [],
    WorkloadDeployUpdateModel: new WorkloadDeployUpdateModel(),
    updateWorkloadDialogState: false,
    networks: [],
    countNetworks: 0,
    errorMessageNetwork: '',
    isWorkloadClickable: false,
    allVersions: [],
  },

  getters: {
    getVersions: (state) => state.versions,
    getAllVersions: (state) => state.allVersions,
    getUpdateWorkloadDialogState: (state) => state.updateWorkloadDialogState,
    networks: (state) => state.networks,
    countNetworks: (state) => state.countNetworks,
    errorMessageNetwork: (state) => state.errorMessageNetwork,
    isWorkloadClickable: (state) => state.isWorkloadClickable,
  },

  mutations: {
    SET_VERSIONS(state, data) {
      const deployableVersions = [];
      data.versions.forEach((version) => {
        if (version.isDeployable) {
          const obj = new WorkloadDeployUpdateModel(version);
          deployableVersions.push(obj);
        }
      });
      state.versions = deployableVersions;
      state.allVersions = data && data.versions;
      state.isWorkloadClickable = data && !!data._id;
    },
    OPEN_UPDATE_WORKLOAD_DIALOG(state) {
      state.updateWorkloadDialogState = true;
    },
    CLOSE_UPDATE_WORKLOAD_DIALOG(state) {
      state.updateWorkloadDialogState = false;
    },
    SET_NETWORK_PROPERTIES(state, networks) {
      state.networks = networks.map((n) => ({
        interfaceName: n.interface_name,
        source: n.source,
        macAddress: n.mac_address,
        ipAddress: n.ip_address && n.ip_address[0] ? `${n.ip_address[0].addr}/${n.ip_address[0].prefix}` : 'N/A',
        receivedBytes: n.received_bytes ? Math.ceil(n.received_bytes / 1000) : 'N/A',
        transmittedBytes: n.transmitted_bytes ? Math.ceil(n.transmitted_bytes / 1000) : 'N/A',
      }));
      state.countNetworks = networks.length;
    },
    SET_ERROR_MESSAGE(state, error) {
      state.errorMessageNetwork = error;
    },
  },
  actions: {
    async fetch({ commit }, workloadId) {
      commit('SET_VERSIONS', await WorkloadsApiService.getWorkloadVersions({ id: workloadId }));
    },
    async update_deployed_workload(_, data) {
      await WorkloadsApiService.deploy(data);
    },
    open_update_workload_dialog({ commit }, data) {
      commit('OPEN_UPDATE_WORKLOAD_DIALOG', data);
    },
    close_update_workload_dialog({ commit }) {
      commit('CLOSE_UPDATE_WORKLOAD_DIALOG');
    },
    async fetch_networks({ commit }, { serialNumber, deviceId, status }) {
      // eslint-disable-next-line no-useless-catch
      try {
        if (status === 'started' || status === 'suspended') {
          commit('SET_NETWORK_PROPERTIES', await NodesApiService.fetchNetworkProperties(serialNumber, deviceId));
          return;
        }
        commit('SET_NETWORK_PROPERTIES', []);
      } catch (e) {
        throw e;
      }
      commit('SET_NETWORK_PROPERTIES', []);
    },
  },
};
