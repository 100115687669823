/* eslint-disable no-underscore-dangle */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import RoleModel from '@/model/roles/role.model';

// eslint-disable-next-line max-classes-per-file
class UserContact {
  contactType = 'email';

  isDefault = true;

  label = 'Default';

  constructor(data = {}) {
    this.contact = data.contact || '';
    this.email = data.contact || '';
  }

  toJSON() {
    return {
      contactType: this.contactType,
      isDefault: this.isDefault,
      label: this.label,
      contact: this.contact,
      email: this.email,
    };
  }
}

export default class UserModel {
  /**
   * Creates an instance of UserModel.
   * @param {Object} category - Category object from the API
   * @class UserModel
   * @property {string} _id - User id
   * @property {string} firstName - User firstName
   * @property {string} lastName - User lastName
   * @property {string} username - User username
   * @property {string} created - created
   * @property {string} type - User type
   * @property {string} profileImgURL - User profileImgURL
   * @property {string} preferredLanguage - preferredLanguage
   * @property {string} contact - User contact
   * @property {string} email - User email
   * @property {Array} roles - User roles
   * @property {string} ldap - ldap
   * @property {string} currentPassword - User currentPassword
   * @property {string} newPassword - User newPassword
   * @property {string} confirmPassword - User confirmPassword
   */
  constructor(data = {}) {
    this.id = data._id;
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.username = data.username || '';
    this.created = data;
    this.type = data.type || '';
    this.profileImgURL = data.profileImgURL || '';
    this.preferredLanguage = data.preferredLanguage ? data.preferredLanguage.split('-')[0] : '';
    this.contact = [new UserContact(data.contact ? data.contact[0] : {})];
    this.email = [new UserContact(data.contact ? data.contact[0] : {})];
    this.roles = data.roles ? data.roles.map((role) => new RoleModel(role)) : [];
    this.ldap = data.ldap || '';

    this.currentPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
  }

  set created(data) {
    this._created = new Date(data.created).toLocaleString('en-GB');
  }

  get created() {
    return this._created;
  }

  toJSON() {
    return {
      ...(this.id && { id: this.id }),
      firstName: this.firstName,
      lastName: this.lastName,
      username: this.username,
      profileImgURL: this.profileImgURL,
      preferredLanguage: this.preferredLanguage,
      contact: this.contact.map((c) => c.toJSON()),
      ...(this.roles && { roles: this.roles.map((role) => role.id) }),
      ...(this.currentPassword && {
        currentPassword: this.currentPassword,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      }),
    };
  }
}
