/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2023. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import BaseApiService from './base.api-service';

const URL = '/nerve/v2/node';

class LabelsApiService extends BaseApiService {
  fetchActivityLogs(params) {
    return this.get(
      `${URL}/${params.serialNumber}/versions/${params.versionId}/activity-logs?search=${params.search}&page=${params.page}&limit=${params.limit}`,
      params,
      { authRequired: true },
    );
  }

  downloadSingle(params) {
    const options = {
      headers: {
        Accept: 'text/csv; application/octet-stream',
      },
      responseType: 'blob',
    };
    return this.get(`${URL}/${params.serialNumber}/versions/${params.versionId}/activity-logs/download`, options, {
      authRequired: true,
    });
  }

  downloadAll(params) {
    const options = {
      headers: {
        Accept: 'text/csv; application/octet-stream',
      },
      responseType: 'blob',
    };
    return this.get(`${URL}/${params.serialNumber}/workloads/activity-logs/download`, options, { authRequired: true });
  }
}

export default new LabelsApiService();
