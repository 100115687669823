/* eslint-disable no-nested-ternary */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import Version from '@/model/workloads/compose-workload-version.model';

export default class ComposeWorkloadModel {
  /**
   * Creates an instance of WorkloadModel.
   * @param {Array} data - WorkloadModel list
   * @class WorkloadModel
   * @property {string} name - Workload name
   * @property {string} type - Workload type
   * @property {string} description - Workload description
   * @property {Boolean} disabled - Is workload disabled
   * @property {Array} versions - Workload versions
   * @property {string} _id - Workload id
   * @property {string} created - Workload created
   */
  constructor(data = {}) {
    this.name = data.name || '';
    this.type = data.type || '';
    this.description = data.description || '';
    this.disabled = data.disabled || false;
    this.versions = data.versions
      ? data.versions.map((v) => new Version({ version: v, type: data.type }))
      : [new Version({ version: {}, type: data.type })];
    this._id = data._id || data.id;
    this.created = data.createdBy && data.createdBy.date ? new Date(data.createdBy.date).toLocaleString('en-GB') : '';
  }
}
