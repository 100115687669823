<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <nerve-dialog
    v-model="dialogState"
    scrollable
    persistent
    max-width="25vw"
    @keydown.esc="close()"
    @keydown.enter="confirm()"
  >
    <v-card id="iiotConfirmDialogCard" class="confirm-dialog">
      <v-card-title class="text-center">
        {{ $t(dialogParams.title) }}
      </v-card-title>
      <v-card-text v-if="dialogParams.subTitle">
        <div>
          {{ $t(dialogParams.subTitle) }}
        </div>
        <div v-if="dialogParams.subTitleSecondLine">
          {{ $t(dialogParams.subTitleSecondLine) }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-checkbox
          v-if="dialogParams.dontAskMeAgainCallback"
          id="confirmDialogDontShowAgainCheckbox"
          v-model="dialogParams.dontAskMeAgain"
          :label="$t('dialog.doNotShowDialogAgainMessage')"
          class="mr-auto"
        />
        <nerve-button
          id="confirmDialogCancelButton"
          :text="$t('dialog.cancel')"
          type-of-btn="close"
          size="normal"
          class="my-0"
          autofocus
          @click-event="close()"
        />
        <nerve-button
          id="confirmDialogYesButton"
          :text="$t('dialog.yes')"
          type-of-btn="action"
          size="normal"
          class="my-0"
          @click-event="confirm()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';

export default {
  name: 'ConfirmDialog',
  components: { NerveButton, NerveDialog },
  computed: {
    dialogState: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getConfirmDialogState']();
      },
      set() {
        this.close();
      },
    },
    dialogParams() {
      return this.$store.getters['utils/_api_request_handler/getConfirmDialogParams']();
    },
  },
  methods: {
    async close() {
      if (this.dialogParams.cancelCallback) {
        await this.dialogParams.cancelCallback();
      }
      this.$store.dispatch('utils/_api_request_handler/close_confirm');
    },
    async confirm() {
      if (this.dialogParams.dontAskMeAgain && this.dialogParams.dontAskMeAgainCallback) {
        this.dialogParams.dontAskMeAgain = false;
        await this.dialogParams.dontAskMeAgainCallback();
      }
      await this.dialogParams.callback();
      this.$store.dispatch('utils/_api_request_handler/close_confirm');
    },
    async handler() {
      this.close();
    },
  },
};
</script>
<style lang="scss" scoped>
.confirm-dialog {
  max-height: 460px !important;
  min-height: 320px !important;
}
</style>
