/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

export default class SnapshotScheduleModel {
  /**
   * Creates an instance of SnapshotScheduleModel.
   * @param {Object} snapshotSchedule - SnapshotScheduleModel object
   * @class SnapshotScheduleModel
   * @property {string} type - Schedule type (Interval and Day)
   * @property {string} intervalTime - Hours
   * @property {string} day - Day of the week
   * @property {string} dayTime - hours:minutes
   * @property {string} vmState - VM state (Current and Stopped)
   */
  constructor(snapshotSchedule = {}) {
    this.type = snapshotSchedule && snapshotSchedule.type ? snapshotSchedule.type : '';
    this.intervalTime = snapshotSchedule && snapshotSchedule.intervalTime ? snapshotSchedule.intervalTime : 1;
    this.day = snapshotSchedule && snapshotSchedule.day ? snapshotSchedule.day : '';
    this.dayTime = snapshotSchedule && snapshotSchedule.dayTime ? snapshotSchedule.dayTime : '';
    this.vmState = snapshotSchedule && snapshotSchedule.vmState ? snapshotSchedule.vmState : '';
  }
}
