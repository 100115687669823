<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
    <g fill="#FFF" fill-rule="nonzero">
      <path
        d="M88.614 37.558c-.238-.19-2.503-1.922-7.282-1.922-1.264
      0-2.515.114-3.767.329-.926-6.399-6.156-9.522-6.394-9.661l-1.276-.747-.838
      1.227a17.62 17.62 0 0 0-2.277 5.387c-.851 3.655-.338 7.082 1.501 10.016-2.215
      1.252-5.78 1.555-6.494 1.58H2.89c-1.539 0-2.79 1.265-2.79 2.808-.062 5.21.8 10.395
      2.578 15.289 2.027 5.374 5.042 9.332 8.971 11.76 4.405 2.72 11.55 4.275 19.657
      4.275 3.667.012 7.32-.329 10.911-1a44.872 44.872 0 0 0 14.24-5.235 39.532 39.532
      0 0 0 9.722-8.042c4.667-5.337 7.445-11.28 9.51-16.567h.825c5.105 0 8.246-2.06
      9.985-3.793a10.887 10.887 0 0 0 2.628-3.946l.363-1.087-.876-.67z"
      />
      <path
        d="M8.346 42.022h7.895a.698.698 0 0 0 .688-.695V34.22a.69.69 0 0
      0-.688-.696H8.346a.698.698 0 0 0-.688.696v7.107c0 .38.313.695.688.695M19.22
      42.022h7.895a.698.698 0 0 0 .688-.695V34.22a.69.69 0 0 0-.688-.696h-7.896a.69.69
      0 0 0-.688.696v7.107c0 .38.313.695.688.695M30.268 42.022h7.895a.698.698 0 0 0
      .689-.695V34.22a.69.69 0 0 0-.689-.696h-7.895a.698.698 0 0 0-.688.696v7.107c0
      .38.3.695.688.695M41.179 42.022h7.895a.698.698 0 0 0 .688-.695V34.22a.69.69 0 0
      0-.688-.696H41.18a.698.698 0 0 0-.688.696v7.107c0 .38.3.695.688.695M19.22
      31.804h7.895a.698.698 0 0 0 .688-.695v-7.107a.698.698 0 0 0-.688-.696h-7.896a.698.698
      0 0 0-.688.696v7.107c0 .38.313.695.688.695M30.268 31.804h7.895a.698.698 0 0 0
      .689-.695v-7.107a.698.698 0 0 0-.689-.696h-7.895a.698.698 0 0 0-.688.696v7.107c0
      .38.3.695.688.695M41.179 31.804h7.895a.69.69 0 0 0
      .688-.695v-7.107a.698.698 0 00-.688-.696H41.18a.698.698
      0 0 0-.688.696v7.107c0 .38.3.695.688.695M41.179 21.574h7.895a.698.698
      0 0 0 .688-.696v-7.107a.698.698 0 0 0-.688-.695H41.18a.698.698 0 0
      0-.688.695v7.107c0 .392.3.696.688.696M52.177 42.022h7.896a.698.698 0
      0 0 .688-.695V34.22a.69.69 0 0 0-.688-.696h-7.896a.698.698 0 0 0-.688.696v7.107c0
      .38.313.695.688.695"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DockerIcon',
};
</script>
