/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import BaseApiService from './base.api-service';

const ENDPOINTS = {
  NODE_UPDATE_LIST: '/nerve/update/local-node-update',
  DETAILS_BY_NAME: '/nerve/update/node/details-by-name',
  SEARCH_COMPATIBLE_DEVICES: '/nerve/devices/search-compatible-devices',
  UPDATE: '/bom/nerve/node/update',
};

class NodeUpdateListApiService extends BaseApiService {
  localNodeUpdate() {
    return this.get(ENDPOINTS.NODE_UPDATE_LIST, { authRequired: true });
  }

  detailsByName(versionName) {
    return this.get(`${ENDPOINTS.DETAILS_BY_NAME}?versionName=${versionName}`, {
      authRequired: true,
    });
  }

  compatibleDevices(params) {
    return this.get(ENDPOINTS.SEARCH_COMPATIBLE_DEVICES, params, { authRequired: true });
  }

  update(selectedDevices, nodeUpdateDetails) {
    return this.post(
      ENDPOINTS.UPDATE,
      { devices: selectedDevices, version: nodeUpdateDetails },

      { authRequired: true },
    );
  }
}

export default new NodeUpdateListApiService();
