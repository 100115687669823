/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import BaseApiService from './base.api-service';

const PATHS = {
  GET_DEPLOY_TASK: 'bom/task/getDeployTasksInDeployment',
  GET_LOG: 'bom/content/get',
  RESET_TASK: 'bom/task/reset',
  RESET_ALL_TASKS: 'bom/task/resetAll',
  MARK_TASK_AS_FAIL: 'bom/task/markAsFail',
  MARK_TASK_AS_SUCCESS: 'bom/task/markTaskAsSuccess',
  BULK_OPERATION: '/bom/deployment/get',
};
class DeployDetailsApiService extends BaseApiService {
  fetchDeploy(params, id) {
    return this.get(`${PATHS.GET_DEPLOY_TASK}/${id}`, params, { authRequired: true });
  }

  getLog(id) {
    return this.get(`${PATHS.GET_LOG}/${id}`, { authRequired: true });
  }

  resetTask(id) {
    return this.post(`${PATHS.RESET_TASK}/${id}`, {}, { authRequired: true });
  }

  resetAllTasks(id) {
    return this.post(`${PATHS.RESET_ALL_TASKS}/${id}`, {}, { authRequired: true });
  }

  markTaskAsFail(id) {
    return this.post(`${PATHS.MARK_TASK_AS_FAIL}/${id}`, {}, { authRequired: true });
  }

  markTaskAsSuccess(id) {
    return this.post(`${PATHS.MARK_TASK_AS_SUCCESS}/${id}`, {}, { authRequired: true });
  }

  getBulkOperationCampaign(id) {
    return this.get(`${PATHS.BULK_OPERATION}/${id}`, { authRequired: true });
  }
}

export default new DeployDetailsApiService();
