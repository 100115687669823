/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2023. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import ComposeFileModel from '@/model/workloads/compose-file.model';

export default class ComposeServiceModel {
  /**
   * Creates an instance of ComposeServiceModel.
   * @param {Object} data - ComposeServiceModel
   * @class ComposeServiceModel
   * defined for each service individually.
   * @property {string} serviceName - name of the service
   * @property {string} dockerFilePath - Docker file path
   * @property {string} dockerFileOption - Docker file option
   * @property {Object} file - docker image
   * @property {string} errorMessage - file error message
   */
  constructor(data = {}) {
    this.serviceName = data.serviceName || '';
    this.dockerFilePath = data.dockerFilePath || '';
    this.dockerFileOption = data.dockerFileOption || this.dockerFilePath;
    this.configStorage = data.configStorage;
    this.dockerConfigurationStorage = data.dockerConfigurationStorage || [];
    this.file = data.file ? new ComposeFileModel(data.file) : {};
    this.errorMessage = data.errorMessage || '';
  }
}
