/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import BaseApiService from './base.api-service';

const PATH = {
  CHECK_IF_EXISTING: '/crm/profile/checkIfExisting',
  FORGOT_PASS: '/auth/forgotPass',
};

class ForgotPasswordApiService extends BaseApiService {
  checkIfExisting(params) {
    return this.post(
      PATH.CHECK_IF_EXISTING,
      { username: params.email, preferredLanguage: params.language },

      { authRequired: true },
    );
  }

  forgotPass(params) {
    return this.post(
      PATH.FORGOT_PASS,
      { email: params.email, language: params.language },

      { authRequired: true },
    );
  }
}

export default new ForgotPasswordApiService();
