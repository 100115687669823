/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-underscore-dangle */
import CategoryModel from '@/model/roles/category.model';

export default class PermissionModel {
  /**
   * Creates an instance of PermissionModel.
   * @param {Object} permission - Permission object from the API
   * @class PermissionModel
   * @property {string} _id - Permission id
   * @property {string} name - Permission name
   * @property {string} human_name - Permission human readable name
   * @property {string} description - Permission description
   * @property {object} category - Permission category
   * @property {array} requires - Ids of permission requires
   * @property {boolean} display - Flag that defines should permission be displayed on frontend
   */

  constructor(permission = {}) {
    this._id = permission._id || '';
    this.name = permission.name || '';
    this.human_name = permission.human_name || '';
    this.description = permission.description || '';
    this.category = new CategoryModel(permission.category || {});
    this.requires = permission.requires || [];
    this.display = permission.display || false;
  }
}
