/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2023. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

export default class ActivityLogModel {
  /**
   * Creates an instance of ActivityLogModel.
   * @class ActivityLogModel
   * @property {string} timestamp - Timestamp
   * @property {string} username - Username
   * @property {string} typeOfAction - Type of action
   * @property {string} workloadVersionName - Workload version name
   * @property {string} result - Result
   */
  constructor(data = {}) {
    this.timestamp = data.timestamp ? new Date(data.timestamp).toLocaleString('en-GB') : '';
    this.username = data.username || '';
    this.typeOfAction = data.typeOfAction || '';
    this.workloadVersionName = data.workloadVersionName || '';
    this.result = data.result || '';
  }
}
