/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import { NodeUpdateDetailsApiService } from '@/services/api';
import NodeUpdateDetailsListModel from '@/model/node-update-details/node-update-details-list.model';

export default {
  state: {
    nodeUpdateDetailsList: [],
    nodeUpdateDetails: [],
    totalDetails: 0,
    content: {},
    bulkOperation: {},
  },
  mutations: {
    SET_NODE_UPDATE_DETAILS_LIST(state, list) {
      state.nodeUpdateDetailsList = list.map((detail) => new NodeUpdateDetailsListModel({ ...detail }));
    },
    SET_TOTAL_DETAILS(state, count) {
      state.totalDetails = count;
    },
    SET_BULK_OPERATION(state, content) {
      state.bulkOperation = content;
    },
    UPDATE_NODE_UPDATE_DETAILS_LIST(state, task) {
      state.nodeUpdateDetailsList.map((detail) => {
        if (detail.id === task._id) {
          detail.progress = task.progress;
          detail.status = task.status.charAt(0) + task.status.slice(1).toLowerCase().replace('_', ' ');
          detail.timeOfTaskStart = new Date(task.timeOfTaskStart).toLocaleString('en-GB');
          detail.retryCounterMax = `${task.triedCounter}/${task.retryCounter}`;
          detail.errorFeedback = task.errorFeedback;
        }
      });
    },
    SET_CONTENT_LOG(state, list) {
      state.content = list;
    },
    UPDATE_TASK(state, payload) {
      state.nodeUpdateDetailsList.map((detail) => {
        if (detail.id === payload.id) {
          detail.progress = payload.progress;
          detail.status = payload.status.charAt(0) + payload.status.slice(1).toLowerCase().replace('_', ' ');
          detail.timeOfTaskStart = new Date(payload.timeOfTaskStart).toLocaleString('en-GB');
          detail.timeOfOperationFinish =
            payload.status === 'SUCCESS'
              ? payload.taskFinishedTimestamp
                ? new Date(payload.taskFinishedTimestamp).toLocaleString('en-GB')
                : 'In progress'
              : payload.status === 'Error' || payload.status === 'FAIL'
                ? new Date(payload.errorFeedback.timeOfFail).toLocaleString('en-GB')
                : 'In progress';
          detail.retryCounterMax = `${payload.triedCounter}/${payload.retryCounter}`;
          detail.errorFeedback = payload.errorFeedback;
          detail.timeOfOperationFinish =
            payload.status === 'SUCCESS'
              ? payload.taskFinishedTimestamp
                ? new Date(payload.taskFinishedTimestamp).toLocaleString('en-GB')
                : ''
              : payload.status === 'Error' || payload.status === 'FAIL'
                ? new Date(payload.errorFeedback.timeOfFail).toLocaleString('en-GB')
                : '';
        }
      });
    },
  },
  getters: {
    getNodeUpdateDetails: (state) => state.nodeUpdateDetailsList,
    getTotalDetails: (state) => state.totalDetails,
    content: (state) => state.content,
    bulkOperation: (state) => state.bulkOperation,
  },
  actions: {
    async fetch({ commit }, data) {
      let params = {};
      if (data && data.id) {
        params = {
          limit: data.itemsPerPage || 10,
          page: data.page || 1,
          filterBy: {
            isFinished: data.isFinished,
            isFailed: data.isFailed,
            isCancelled: data.isCanceled,
            inProgress: data.inProgress,
          },
        };
        if (data.search !== '') {
          params.filterBy.device = data.search;
        }
        const list = await NodeUpdateDetailsApiService.nodeUpdateDetailsList({ params }, data.id);
        commit('SET_NODE_UPDATE_DETAILS_LIST', list.data);
        commit('SET_TOTAL_DETAILS', list.count);
      }
    },
    async mark_as_finish({ commit }, taskId) {
      const task = await NodeUpdateDetailsApiService.markAsFinish(taskId);
      commit('UPDATE_NODE_UPDATE_DETAILS_LIST', task);
    },
    updated_task({ commit }, payload) {
      commit('UPDATE_TASK', payload.params);
    },
    // eslint-disable-next-line no-unused-vars
    async get_bulk_operation({ commit }, campId) {
      const content = await NodeUpdateDetailsApiService.getBulkOperationCampaign(campId);
      commit('SET_BULK_OPERATION', content);
    },
  },
};
