/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import BaseApiService from './base.api-service';

const ENDPOINTS = {
  CONTENT: '/bom/content/get',
  NODE_UPDATE_DETAILS_LIST: '/bom/task/getDeployTasksInDeployment',
  MARK_AS_FINISH: '/bom/task/markAsFinish',
  BULK_OPERATION: '/bom/deployment/get',
};

class NodeUpdateDetailsApiService extends BaseApiService {
  getContent(id) {
    return this.get(`${ENDPOINTS.CONTENT}/${id}`, { authRequired: true });
  }

  nodeUpdateDetailsList(params, logId) {
    return this.get(`${ENDPOINTS.NODE_UPDATE_DETAILS_LIST}/${logId}`, params, {
      authRequired: true,
    });
  }

  markAsFinish(id) {
    return this.get(`${ENDPOINTS.MARK_AS_FINISH}/${id}`, { authRequired: true });
  }

  getBulkOperationCampaign(id) {
    return this.get(`${ENDPOINTS.BULK_OPERATION}/${id}`, { authRequired: true });
  }
}

export default new NodeUpdateDetailsApiService();
