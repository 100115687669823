/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import BaseApiService from './base.api-service';

const PATH = 'nerve/node';

const REMOTE_CONNECTIONS_PATH = '/nerve/remoteConnections';

const GET_REMOTE_LIST = 'nerve/workload';

const REMOTE_CONNECT = 'nerve/remoteConnections/connect/';

const CANCEL_REMOTE_CONNECT = 'nerve/activeRemoteConnections/cancelConnectionApproval';

const GUACAMOLE_CONNECTION_TAB = 'nerve/guacamoleConnectionTab';

const IMPORT_RC = 'nerve/v2/remote-connections-file';

class RemoteConnectionApiService extends BaseApiService {
  updateNode(data) {
    return this.patch(`${PATH}`, data, { authRequired: true });
  }

  fetchRc(id) {
    return this.get(`${PATH}/${id}`, { authRequired: true });
  }

  updateRemoteConnection(id, connection, versionId) {
    return this.patch(`${REMOTE_CONNECTIONS_PATH}/version/${id}`, { connection, versionId }, { authRequired: true });
  }

  addRemoteConnection(id, connection) {
    return this.post(`${REMOTE_CONNECTIONS_PATH}/${id}`, { connection }, { authRequired: true });
  }

  getWlWithRc(id) {
    return this.get(`${GET_REMOTE_LIST}/${id}`, { authRequired: true });
  }

  remoteConnect(data) {
    return this.post(`${REMOTE_CONNECT}`, data, { authRequired: true });
  }

  cancelRemoteConnApproval(activeConnRequestUid) {
    return this.delete(`${CANCEL_REMOTE_CONNECT}/${activeConnRequestUid}`, { authRequired: true });
  }

  guacamoleConnectionTab(data) {
    return this.post(`${GUACAMOLE_CONNECTION_TAB}/${data.authToken}/${data.identifier}`, data, {
      authRequired: true,
    });
  }

  importRemoteConnections(params) {
    return this.post(`${IMPORT_RC}/${params.target}/validate`, params.formData, { authRequired: true });
  }
}

export default new RemoteConnectionApiService();
