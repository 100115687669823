/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
export default class WorkloadPropertiesModel {
  /**
   * Creates an instance of WorkloadPropertiesModel.
   * @param {Object} data - WorkloadPropertiesModel
   * @class WorkloadPropertiesModel
   * @property {Array} port_mappings_protocol - port mappings protocol
   * @property {Array} environment_variables - environment variables
   * @property {Object} limit_memory - limit memory
   * @property {String} container_name - container name
   * @property {Array} networks - networks
   * @property {Number} limit_CPUs - limit CPUs
   * @property {String} restart_policy - restart policy
   * @property {Array} docker_volumes - docker volumes
   * @property {Object} snapshot - snapshot
   * @property {Array} data_disks - data disks
   * @property {Array} libvirt_networks - libvirt networks
   * @property {Object} memory - memory
   * @property {Number} no_of_vCPUs - number of CPUs
   * @property {Array} PCI_passthrough - PCI passthrough
   * @property {Object} auth_credentials - auth credentials
   * @property {string} type - Workload type
   */
  constructor(data = {}) {
    if (data.type === 'docker') {
      this.port_mappings_protocol = data.workloadProperties.port_mappings_protocol || [];
      this.environment_variables = data.workloadProperties.environment_variables || [];
      this.limit_memory = data.workloadProperties.limit_memory || { unit: 'MB' };
      this.container_name = data.workloadProperties.container_name || '';
      this.networks = data.workloadProperties.networks || ['bridge'];
      this.limit_CPUs = data.workloadProperties.limit_CPUs || '';
      this.restart_policy = data.workloadProperties.restart_policy || '';
      this.docker_volumes = data.workloadProperties.docker_volumes || [];
      this.auth_credentials = data.workloadProperties.auth_credentials || {
        username: '',
        password: '',
      };
      return;
    }
    this.snapshot = data.workloadProperties.snapshot || { enabled: false };
    this.data_disks = data.workloadProperties.data_disks || [];
    this.libvirt_networks = data.workloadProperties.libvirt_networks || [];
    this.memory = data.workloadProperties.memory || { unit: 'MB' };
    this.no_of_vCPUs = data.workloadProperties.no_of_vCPUs || '';
    this.PCI_passthrough = data.workloadProperties.PCI_passthrough || [];
  }
}
