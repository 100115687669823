<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <nerve-dialog v-model="dialogState" persistent scrollable width="300">
    <v-card color="primary" class="loading-bar" dark>
      <v-card-title>
        {{ $t('loadingBar.text') }}
        <v-progress-linear indeterminate color="white" class="mb-0" />
      </v-card-title>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveDialog } from 'nerve-ui-components';

export default {
  name: 'ApiLoadingBar',
  components: { NerveDialog },
  computed: {
    dialogState() {
      return this.$store.getters['utils/_api_request_handler/getLoadingBarState']();
    },
  },
};
</script>
<style scoped>
.loading-bar {
  min-height: 70px;
  max-height: inherit;
}
</style>
