/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

class ActiveRemoteConnectionsHelper {
  /**
   * Method that will prepare order parameter
   * before sending request to fetch list of active remote connections
   * @param sortBy
   * @param sortDesc
   * @returns {{}|{created: string}}
   */
  // eslint-disable-next-line class-methods-use-this
  handleOrder({ sortBy, sortDesc }) {
    if (!sortBy || !sortDesc) {
      return { created: 'desc' };
    }

    if (!sortBy.length && !sortDesc.length) {
      return { created: 'desc' };
    }

    return { [sortBy[0] ? sortBy[0] : 'created']: sortDesc[0] ? 'desc' : 'asc' };
  }
}
export default new ActiveRemoteConnectionsHelper();
