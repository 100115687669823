/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

export default {
  dark: false,
  options: {
    customProperties: true,
  },
  themes: {
    light: {
      primary: '#0093D0',
      secondary: '#ffffff',
      tertiary: '#081a25',
      accent: '#0093d0',
      error: '#701825',
      info: '#00d3ee',
      treeNode: '#f0f2f4',
      treeNodeExpanded: '#E2F3FF',
      stepperColor: '#c1c1c1',
      stepperBackground: '#e9e9e9',
      success: '#5cb860',
      warning: '#ffa21a',
      gray: '#9c9c9c',
      TTTechOrange: '#ff6100',
      darkBlue: '#4466af',
      hoverPrimary: '#5BB8E5',
      hoverSecondary: '#506B8C',
      tertiaryBtn: '#00294E',
    },
    dark: {
      primary: '#00294E',
      secondary: '#081a25',
      tertiary: '#ffffff',
      accent: '#82B1FF',
      error: '#701825',
      info: '#00d3ee',
      success: '#5cb860',
      warning: '#ffa21a',
      TTTechBlue: '#0093d0',
      TTTechOrange: '#ff6100',
    },
  },
};
