/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2019. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
export default class RemoteConnectionModel {
  /**
   * Creates an instance of Remote Connection Model.
   * @param {Array} data - RemoteConnectionModel list
   * @class RemoteConnectionModel
   * @property {string} id - Connection id
   * @property {string} name - Connection name
   * @property {string} location - Connection location
   * @property {string} connection - Connection type
   * @property {number} numberOfConnections - Number of connection
   * @property {string} acknowledgment - Local acknowledgment
   * @property {String} hostname - hostname
   * @property {number} port - port on node
   * @property {number} localPort - local port
   * @property {number} autoretry -autoretry
   * @property {boolean} swapRedBlue -swap red blue
   * @property {string} cursor - cursor
   * @property {boolean} readOnly - read only
   * @property {string} username - username
   * @property {string} password - password
   * @property {string} type - type
   * @property {string} privateKey - private key
   * @property {string} securityMode - security mode
   * @property {boolean} ignoreServerCertificate - ignore server certificate
   */
  constructor(data = {}) {
    // eslint-disable-next-line no-multi-assign
    this.id = this._id = data._id;
    this.type = data.type || '';
    this.name = data.name || '';
    this.acknowledgment = data.acknowledgment || '';
    this.hostname = data.hostname || '';
    this.port = data.port || 0;
    this.localPort = data.localPort || 0;
    this.headerType = data.connection ? data.connection : data.type;
    this.serviceName = data.serviceName || '';

    if (this.type === 'SCREEN') {
      this.connection = data.connection || '';
      this.numberOfConnections = data.numberOfConnections || 0;
      this.autoretry = data.autoretry || 0;
      this.swapRedBlue = data.swapRedBlue || false;
      this.cursor = data.cursor || '';
      this.readOnly = data.readOnly || false;
      this.password = data.password || '';
      this.location = data.location || '';

      if (this.connection === 'RDP') {
        this.username = data.username || '';
        this.password = data.password || '';
        this.ignoreServerCertificate = data.ignoreServerCertificate || false;
        this.securityMode = data.securityMode || '';
      }
      if (this.connection === 'SSH') {
        this.username = data.username || '';
        this.password = data.password || '';
        this.privateKey = data.privateKey || '';
      }
    }
  }
}
