<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2021. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <v-snackbar
    id="iiotApiToastSnackbar"
    v-model="dialogState"
    :timeout="-1"
    :right="true"
    :top="true"
    :color="toastParams.color"
    data-cy="iiotApiToastSnackbar"
  >
    {{ toastText }}
    <template #action="{ attrs }">
      <v-btn
        v-if="toastParams.showClose"
        id="iiotApiToastCloseBtn"
        data-cy="iiotApiToastCloseBtn"
        v-bind="attrs"
        icon
        autofocus
        @click="closeToast()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'ApiToast',
  computed: {
    dialogState: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getToastState']();
      },
      set() {},
    },
    toastText: {
      get() {
        const params = this.$store.getters['utils/_api_request_handler/getToastParams']();
        // Additional check for space is workaround for already translated texts
        // this will not work for single words, but already translated texts shall not
        // be passed here anyway
        if (params.text.indexOf(' ') !== -1) {
          // Since it contains space, it may not be a key, so it is already translated.
          return params.text;
        }
        if (this.$t(params.text) === params.text) {
          return this.$t('errorMessages.nerve_unknown_err');
        }
        return params.textParameters
          ? this.$t(params.text, { textParams: params.textParameters })
          : this.$t(params.text);
      },
      set() {},
    },
    toastParams: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getToastParams']();
      },
      set() {},
    },
  },
  methods: {
    closeToast() {
      this.$store.dispatch('utils/_api_request_handler/close_toast');
    },
  },
};
</script>
<style lang="scss">
.v-snack__content {
  overflow-wrap: anywhere !important;
}
</style>
