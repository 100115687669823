/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import LabelModel from '@/model/label/label.model';
import DeployedWorkloadModel from '@/model/node/sub-models/deployed-workload.model';
import DeployedComposeWorkloadModel from '@/model/node/sub-models/deployed-compose-workload.model';
import RemoteConnectionModel from '@/model/node/remoteConnection.model';
import shared from '../../helpers/shared';

export const NODE_MODELS = {
  MFN100: 'mfn-100',
};

export const CONNECTION_STATUSES = {
  ONLINE: 'online',
  OFFLINE: 'offline',
};

function setLastSystemStartValue(data) {
  if (!data.lastSystemStart) {
    return '';
  }
  if (Number.isNaN(Number(data.lastSystemStart))) {
    return data.lastSystemStart;
  }
  return new Date(data.lastSystemStart).toLocaleString('en-GB');
}

export default class NodeModel {
  /**
   * Creates an instance of NodeModel.
   * @param {Object} data - NodeModel
   * @class NodeModel
   * @property {string} name - Node name
   * @property {string} nodeId - Node id
   * @property {string} remoteConnections - Node remoteConnections
   * @property {string} connectionStatus - Node connectionStatus
   * @property {string} version - Node version
   * @property {string} serialNumber - Node serialNumber
   * @property {string} secureId - Node secureId
   * @property {string} model - Node model
   * @property {string} labels - Node labels
   */
  constructor(data = {}) {
    // eslint-disable-next-line no-multi-assign
    this.id = this._id = data._id;
    this.name = data.name || '';
    this.timezone = data.timezone || {};
    this.nodeId = data._id || data.nodeId || '';
    this.remoteConnections = data.remoteConnections
      ? data.remoteConnections.map((rc) => new RemoteConnectionModel(rc))
      : [];
    this.connectionStatus = data.connectionStatus || '';
    this.currentFWVersion = data.currentFWVersion || '';
    this.serialNumber = data.serialNumber || '';
    this.secureId = data.secureId || '';
    this.model = data.model || NODE_MODELS.MFN100;
    this.labels = data.labels ? data.labels.map((l) => new LabelModel(l)) : [];
    this.userId = data.userId || '';
    this.created = data;
    if (data.ip_address || data.wanAddress) {
      // hack for preserving wan address
      this.wanAddress = data.ip_address || data.wanAddress || '';
    }
    this.cpu = this.isOnline() ? data.cpu : 0;
    this.docker = this.isOnline() ? data.docker || {} : {};
    this.lvm = this.isOnline() ? data.lvm || {} : {};
    this.mem = this.isOnline() ? data.mem || {} : {};
    // eslint-disable-next-line max-len
    if (data.workloadStats && Array.isArray(data.workloadStats) && data.workloadStats.length !== 0) {
      const devices = [];
      data.devices.forEach((wlDevice) => {
        let foundStats = false;
        data.workloadStats.forEach((wlStats) => {
          if (wlDevice.id === wlStats.deviceId) {
            foundStats = true;
            if (
              (wlDevice._type === 'dockerCompose' || wlDevice._type === 'Docker Compose') &&
              shared.isNoPrerelesedVersionGreaterThanOrEqualTo(data.currentFWVersion, '2.7.0')
            ) {
              devices.push(new DeployedComposeWorkloadModel(Object.assign(wlDevice, { wlStats })));
            } else {
              devices.push(new DeployedWorkloadModel(Object.assign(wlDevice, { wlStats })));
            }
          }
        });
        if (!foundStats) {
          if (
            (wlDevice._type === 'dockerCompose' || wlDevice._type === 'Docker Compose') &&
            shared.isNoPrerelesedVersionGreaterThanOrEqualTo(data.currentFWVersion, '2.7.0')
          ) {
            devices.push(new DeployedComposeWorkloadModel(Object.assign(wlDevice)));
          } else {
            devices.push(new DeployedWorkloadModel(Object.assign(wlDevice)));
          }
        }
      });
      this.devices = devices;
    } else {
      this.devices = data.devices
        ? data.devices.map((d) =>
            (d.type === 'docker-compose' || d._type === 'Docker Compose') &&
            shared.isNoPrerelesedVersionGreaterThanOrEqualTo(data.currentFWVersion, '2.7.0')
              ? new DeployedComposeWorkloadModel(d)
              : new DeployedWorkloadModel(d),
          )
        : [];
    }
    this.lastSystemStart = setLastSystemStartValue(data);
    this.capabilities = data.capabilities || [];
    this.vmSnapshot = data.vmSnapshot || false;
    this.vmBackup = data.vmBackup || false;
  }

  set created(data) {
    if (data.created) {
      this._created = data.created.includes('/') ? data.created : new Date(data.created).toLocaleString('en-GB');
      return;
    }
    this._created = data._created;
  }

  get created() {
    return this._created;
  }

  isOnline() {
    return this.connectionStatus === CONNECTION_STATUSES.ONLINE;
  }

  get transformedLabels() {
    return this.labels.reduce(
      (transformedString, l, index) =>
        `${transformedString} ${l.transformed}${index + 1 < this.labels.length ? ',' : ''}`,
      '',
    );
  }

  get filterVMs() {
    return this.devices
      .reduce((list, current) => {
        if (!!current.id && current.type === 'vm') {
          list.push({
            name: current.name,
            deviceId: current.id,
            display: current.videoOutput.enabled,
            sequence: current.videoOutput.sequence,
          });
        }
        return list;
      }, [])
      .sort((w1, w2) => w1.sequence - w2.sequence);
  }
}
