/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import BaseApiService from './base.api-service';

const URL = '/nerve/workload/node';
const URL_LOCAL_REPOSITORY = '/nerve/node';

class BackupApiService extends BaseApiService {
  fetchBackups(params) {
    return this.get(`${URL}/${params.serialNumber}/backups/${params.deviceId}`, {
      authRequired: true,
    });
  }

  createBackup(params) {
    return this.post(`${URL}/${params.serialNumber}/backups/${params.deviceId}`, params.reqObj, {
      authRequired: true,
    });
  }

  retryBackup(params) {
    return this.post(`${URL}/${params.serialNumber}/backups/${params.deviceId}/restart`, params.reqObj, {
      authRequired: true,
    });
  }

  isLocalRepositoryConfigured(serialNumber) {
    return this.get(`${URL_LOCAL_REPOSITORY}/${serialNumber}/repository/isConfigured`, {
      authRequired: true,
    });
  }
}
export default new BackupApiService();
