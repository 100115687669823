/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

export default class BackupModel {
  /**
   * Creates an instance of BackupModel.
   * @param {Object} backup - Backup object
   * @class BackupModel
   * @property {string} name - Backup name
   * @property {string} started - Backup started timestamp
   * @property {string} finished - Backup finished timestamp
   * @property {string} status - status of the backup
   * @property {string} error -  error that has occurred while trying to create backup
   * @property {string} timeOfError - time when error has occurred
   * @property {integer} progress - progress of backup creation
   */
  constructor(backup = {}) {
    this.name = backup.name || '';
    this.started = backup.started || '-';
    this.finished = backup.finished || '-';
    this.status = backup.status || '';
    this.error = backup.error || '';
    this.timeOfError = backup.timeOfError || '';
    this.progress = backup.progress || 0;
  }
}
