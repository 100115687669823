/* eslint-disable no-underscore-dangle */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

export default class DeployDetailsModel {
  /**
   * Creates an instance of DeployDetailsModel.
   * @class DeployDetailsModel
   * @property {string} id - Id
   * @property {string} device - Device
   * @property {boolean} action - Deploy action
   * @property {String} retryCounter- Status operation
   * @property {string} started - Time when operation is started
   * @property {string} finished - Time when operation is finished
   * @property {String} status - Status operation
   * @property {Number} progress - Progress percent
   *
   */
  constructor(data = {}) {
    this.id = data._id || '';
    this.device = data.device || '';
    this.action = data.deploymentType;
    this.retryCounter = `${data.triedCounter}/${data.retryCounter}`;
    this.started = data.timeOfTaskStart ? new Date(data.timeOfTaskStart).toLocaleString('en-GB') : '';

    this.finished =
      // eslint-disable-next-line no-nested-ternary
      data.taskOptions.status === 'SUCCESS'
        ? data.taskFinishedTimestamp
          ? new Date(data.taskFinishedTimestamp).toLocaleString('en-GB')
          : ''
        : data.taskOptions.status === 'Error' || data.taskOptions.status === 'FAILED'
          ? new Date(data.errorFeedback.timeOfFail).toLocaleString('en-GB')
          : '';
    this.status =
      data.taskOptions.status.charAt(0) + data.taskOptions.status.slice(1).toLowerCase().replace('_', ' ') || '';
    this.errorFeedback = data.errorFeedback;
    this.progress = data.taskOptions.progress.toFixed(2);
  }
}
