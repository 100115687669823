/* eslint-disable consistent-return */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import store from '@/store';
import { LdapApiService } from '@/services/api';
import LdapModel from '@/model/local-repository/ldap/ldap.model';

export default {
  state: {
    defaultLdap: new LdapModel(),
    isConnectionCorrect: null,
    queryResponseGroups: [],
    queryResponseUsers: [],
    testConnectionResponse: false,
    saveResponse: false,
    syncResponse: false,
    ldapActiveResponse: false,
    authState: false,
    countGroups: 0,
    countUsers: 0,
    isLdapActive: false,
  },
  getters: {
    getDefaultLdap: (state) => state.defaultLdap,
    getStatusOfTestConnection: (state) => state.isConnectionCorrect,
    queryResponseGroups: (state) => state.queryResponseGroups,
    queryResponseUsers: (state) => state.queryResponseUsers,
    testConnectionResponse: (state) => state.testConnectionResponse,
    saveResponse: (state) => state.saveResponse,
    syncResponse: (state) => state.syncResponse,
    ldapActiveResponse: (state) => state.ldapActiveResponse,
    authState: (state) => state.authState,
    totalRowsGroups: (state) => state.countGroups,
    totalRowsUsers: (state) => state.countUsers,
    isLdapActive: (state) => () => state.isLdapActive,
  },
  mutations: {
    SET_DEFAULT_LDAP(state, defaultLdap) {
      state.defaultLdap = new LdapModel(defaultLdap);
    },
    UPDATE_LDAP(state, ldap) {
      Object.assign(state.defaultLdap, ldap);
    },
    CREATE_LDAP(state, ldap) {
      Object.assign(state.defaultLdap, ldap);
    },
    UPDATE_TEST_CONNECTION_STATUS(state, status) {
      localStorage.setItem('connectionStatus', status);
      if (typeof status === 'string' && status !== 'null') {
        state.isConnectionCorrect = status === 'true';
        return;
      }
      state.isConnectionCorrect = status;
    },
    UPDATE_TEST_CONNECTION_STATUS_WITHOUT_SAVE(state, status) {
      state.isConnectionCorrect = status;
    },
    UPDATE_LDAP_ACTIVATION_STATUS(state, status) {
      state.defaultLdap.active = status;
    },
    UPDATE_TIME_TO_ZERO(state) {
      state.defaultLdap.recurringSync.time = null;
    },
    RESPONSE_GROUPS(state, res) {
      state.queryResponseGroups = res.groups;
      state.countGroups = res.count;
    },
    RESPONSE_USERS(state, res) {
      state.queryResponseUsers = res.users;
      state.countUsers = res.count;
    },
    TEST_CONNECTION_RESPONSE(state, value) {
      state.testConnectionResponse = value;
    },
    SAVE_RESPONSE(state, value) {
      state.saveResponse = value;
    },
    SYNC_RESPONSE(state, value) {
      state.syncResponse = value;
    },
    LDAP_ACTIVE_RESPONSE(state, value) {
      state.ldapActiveResponse = value;
    },
    CHANGE_AUTH_STATE(state, value) {
      state.authState = value;
    },
    SET_LDAP_ACTIVE(state, value) {
      state.isLdapActive = value;
    },
  },
  actions: {
    async get_default_ldap({ commit }) {
      try {
        commit('SET_DEFAULT_LDAP', await LdapApiService.getDefaultLdap());
        commit('CHANGE_AUTH_STATE', false);
      } catch (e) {
        commit('SET_DEFAULT_LDAP', []);
        commit('UPDATE_TEST_CONNECTION_STATUS', null);
        commit('CHANGE_AUTH_STATE', true);
        throw e;
      }
    },
    async create_ldap({ commit }, ldap) {
      try {
        commit('SAVE_RESPONSE', true);
        const response = await LdapApiService.create(ldap);
        commit('CREATE_LDAP', response);
        commit('SAVE_RESPONSE', false);
        commit('CHANGE_AUTH_STATE', false);
        store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'ldap.createLdapMessage',
          color: 'success',
          showClose: true,
        });
      } catch (e) {
        commit('SAVE_RESPONSE', false);
        commit('CHANGE_AUTH_STATE', true);
        throw e;
      }
    },
    async update_ldap({ commit }, ldap) {
      try {
        commit('SAVE_RESPONSE', true);
        await LdapApiService.update(ldap);
        commit('UPDATE_LDAP', ldap);
        commit('SAVE_RESPONSE', false);
        localStorage.setItem('isLdapActive', ldap.active);
        store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'ldap.updateLdapMessage',
          color: 'success',
          showClose: true,
        });
      } catch (e) {
        commit('SAVE_RESPONSE', false);
        throw e;
      }
    },
    async test_groups_sync({ commit }, parameters = {}) {
      try {
        const params = {
          limit: parameters.itemsPerPage || 10,
          page: parameters.page || 1,
        };
        const res = await LdapApiService.testGroupsSync({ ldap: parameters.ldap, paging: params });
        commit('RESPONSE_GROUPS', res);
        return res;
      } catch (e) {
        commit('RESPONSE_GROUPS', []);
        throw e;
      }
    },
    async test_users_sync({ commit }, parameters = {}) {
      try {
        const params = {
          limit: parameters.itemsPerPage || 10,
          page: parameters.page || 1,
        };
        const res = await LdapApiService.testUsersSync({ ldap: parameters.ldap, paging: params });
        commit('RESPONSE_USERS', res);
        return res;
      } catch (e) {
        commit('RESPONSE_USERS', []);
        throw e;
      }
    },
    async test_connection({ commit }, ldap) {
      try {
        commit('TEST_CONNECTION_RESPONSE', true);
        const res = await LdapApiService.testConnection(ldap);
        commit('UPDATE_TEST_CONNECTION_STATUS', res.connected);
        commit('TEST_CONNECTION_RESPONSE', false);
      } catch (e) {
        commit('TEST_CONNECTION_RESPONSE', false);
        throw e;
      }
    },
    set_test_connection_status({ commit }, status) {
      commit('UPDATE_TEST_CONNECTION_STATUS', status);
    },
    set_test_connection_status_without_save({ commit }, status) {
      commit('UPDATE_TEST_CONNECTION_STATUS_WITHOUT_SAVE', status);
    },
    async sync({ commit }, ldap) {
      try {
        commit('SYNC_RESPONSE', true);
        await LdapApiService.sync(ldap);
        commit('SYNC_RESPONSE', false);
        store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'ldap.syncLdapMessage',
          color: 'success',
          showClose: true,
        });
      } catch (e) {
        commit('SYNC_RESPONSE', false);
        throw e;
      }
    },
    async change_ldap_activation({ commit }, ldap) {
      try {
        commit('LDAP_ACTIVE_RESPONSE', true);
        await LdapApiService.setActiveLdap({ name: ldap.fileName, active: ldap.active });
        commit('UPDATE_LDAP_ACTIVATION_STATUS', ldap.active);
        localStorage.setItem('isLdapActive', ldap.active);
        if (ldap.active) {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'ldap.LdapActivated',
            color: 'success',
            showClose: true,
          });
        } else {
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'ldap.LdapDeactivated',
            color: 'success',
            showClose: true,
          });
        }
        commit('LDAP_ACTIVE_RESPONSE', false);
      } catch (e) {
        commit('UPDATE_LDAP_ACTIVATION_STATUS', !ldap.active);
        commit('LDAP_ACTIVE_RESPONSE', false);
        throw e;
      }
    },
    set_time_to_zero({ commit }) {
      commit('UPDATE_TIME_TO_ZERO');
    },
    async get_active_ldap({ commit }) {
      const response = await LdapApiService.getActiveLdap();
      commit('SET_LDAP_ACTIVE', response.active);
    },
  },
};
