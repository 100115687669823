/* eslint-disable no-nested-ternary */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

export default class NodeUpdateDetailsModel {
  /**
   * Creates an instance of NodeUpdateDetailsModel.
   * @class NodeUpdateDetailsModel
   * @property {string} id - id
   * @property {string} device - device
   * @property {string} timeOfOperationFinish - time when operation is started
   * @property {string} timeOfTaskStart - time when operation is finished
   * @property {String} status - status operation
   * @property {String} retryCounterMax - status operation
   * @property {Number} progress - progress
   * @property {Object} errorFeedback - error feedback
   * @property {Boolean} isFailed - isFailed
   *
   */
  constructor(data = {}) {
    this.id = data._id || '';
    this.timeOfTaskStart = data.timeOfTaskStart ? new Date(data.timeOfTaskStart).toLocaleString('en-GB') : '';
    this.timeOfOperationFinish =
      data.taskOptions.status === 'SUCCESS'
        ? data.taskFinishedTimestamp
          ? new Date(data.taskFinishedTimestamp).toLocaleString('en-GB')
          : 'In progress'
        : data.taskOptions.status === 'Error' || data.taskOptions.status === 'FAIL'
          ? new Date(data.errorFeedback.timeOfFail).toLocaleString('en-GB')
          : 'In progress';
    this.status =
      data.taskOptions.status.charAt(0) + data.taskOptions.status.slice(1).toLowerCase().replace('_', ' ') || '';
    this.device = data.device;
    this.retryCounterMax = `${data.triedCounter}/${data.retryCounter}`;
    this.progress = data.taskOptions.progress;
    this.errorFeedback = data.errorFeedback;
    this.isFailed = data.isFailed;
  }
}
