<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2024. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <v-overlay v-if="showLoader">
    <v-progress-circular id="iiotApiLoadingCircular" indeterminate class="mb-0 progress-circular" size="70" width="7" />
  </v-overlay>
</template>

<script>
export default {
  name: 'ApiLoadingCircular',
  computed: {
    showLoader() {
      return this.$store.getters['utils/_api_request_handler/getLoaderCircularState'];
    },
  },
};
</script>
<style scoped>
.progress-circular {
  color: var(--v-primary-base);
}
</style>
