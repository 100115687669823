/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

export default class WorkloadDeployUpdateModel {
  /**
   * Creates an instance of WorkloadDeployUpdateModel.
   * @class WorkloadDeployUpdateModel
   * @property {string} id - Version id
   * @property {string} name - Version name
   * @property {string} releaseName - Release name
   * @property {Array} remoteConnections - Remote connection
   */
  constructor(data = {}) {
    this.id = data._id || '';
    this.name = data.name || '';
    this.releaseName = data.releaseName || '';
    this.remoteConnections = data.remoteConnections || [];
  }
}
