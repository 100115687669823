/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import { cloneDeep } from 'lodash';
import { NodesApiService } from '@/services/api';
import NodeModel from '@/model/node/node.model';
import store from '@/store';
import i18n from '@/i18n';

export default {
  state: {
    nodeData: new NodeModel({}),
    hardware: [],
    nodes: [],
    count: 0,
    selectedNodesList: [],
    monitoringAndLoggingSettingsDialog: false,
    monitoringAndLoggingSettings: {},
    logLevelList: [],
    initialLogLevelList: [],
    delete: false,
    timezoneName: '',
    isSaveEnabled: false,
    isViewSystemSaveEnabled: false,
    isDockerSystemSaveEnabled: false,
    isLogLevelSaveEnabled: false,
    isUpdate: false,
    monitoringAndLoggingSettingsInitialValues: {},
    oldNodeData: {},
    timeout: {
      hours: 1,
      minutes: 0,
    },
  },
  mutations: {
    SET_TIMEOUT(state, value) {
      state.timeout = value;
    },
    SET_SAVE_ENABLED(state, value) {
      state.isSaveEnabled = value;
    },
    SET_VIEW_SYSTEM_SAVE_ENABLED(state, value) {
      state.isViewSystemSaveEnabled = value;
    },
    SET_LOG_SYSTEM_SAVE_ENABLED(state, value) {
      state.isLogLevelSaveEnabled = value;
    },
    SET_DOCKER_SYSTEM_SAVE_ENABLED(state, value) {
      state.isDockerSystemSaveEnabled = value;
    },
    SET_LOG_LEVEL_LIST(state, list) {
      state.logLevelList = list;
      state.initialLogLevelList = { ...list };
    },
    SET_NODE_DATA(state, nodeData) {
      state.nodeData = new NodeModel(nodeData);
      state.timezoneName = state.nodeData.timezone.name;
    },
    SET_OLD_NODE(state) {
      state.oldNodeData = cloneDeep(state.nodeData);
    },
    SET_NODES(state, nodes) {
      state.nodes = nodes.data.map((label) => new NodeModel(label));
      state.count = nodes.count;
    },
    REMOVE_NODE(state, id) {
      state.nodes.splice(
        state.nodes.findIndex((label) => label.id === id),
        1,
      );
    },
    SET_REMOTE_CONNECTION(state, rc) {
      state.nodeData.remoteConnections = rc;
    },
    SET_NODE_IN_LIST(state, node) {
      const nodeInState = state.nodes.find((n) => n.id === node.id);
      if (!nodeInState) {
        return;
      }

      Object.assign(nodeInState, node);
    },
    SELECTED_NODE_LIST(state, node) {
      const nodeListIndex = state.selectedNodesList.findIndex((n) => n.id === node.id);
      if (nodeListIndex !== -1) {
        state.selectedNodesList.splice(nodeListIndex, 1);
        return;
      }
      state.selectedNodesList.push(node);
    },
    REMOVE_SELECTED_NODE(state, node) {
      state.selectedNodesList.splice(
        state.selectedNodesList.findIndex((n) => n.id === node.id),
        1,
      );
    },
    DESELECT_NODES_ON_PAGE(state) {
      state.nodes.forEach((node) => {
        const nodeListIndex = state.selectedNodesList.findIndex((n) => n.id === node.id);
        state.selectedNodesList.splice(nodeListIndex, 1);
      });
    },
    SELECT_NODES_ON_PAGE(state) {
      state.nodes.forEach((node) => {
        if (state.selectedNodesList.findIndex((n) => n.id === node.id) === -1) {
          state.selectedNodesList.push(node);
        }
      });
    },
    DESELECT_ALL_NODES(state) {
      state.selectedNodesList = [];
    },
    LOG_AND_METRICS: (state, params) => {
      state.monitoringAndLoggingSettings = params;
      state.monitoringAndLoggingSettingsInitialValues = cloneDeep(params);
    },
    SET_INIT_VALUE_LOG_METRICS_AFTER_SAVE: (state, params) => {
      state.monitoringAndLoggingSettingsInitialValues = cloneDeep(params);
    },
    SET_INIT_VALUE_LOG_METRICS_V2_AFTER_SAVE: (state, params) => {
      state.monitoringAndLoggingSettingsInitialValues = cloneDeep(params);
    },
    MONITORING_LOGGING_SETTINGS: (state, params) => {
      let lib_level;
      let lib_options;
      if (params?.logLevels?.values?.libvirt?.libvirt_ctl?.options) {
        lib_level = params.logLevels.values.libvirt.libvirt_ctl.level;
        lib_options = params.logLevels.values.libvirt.libvirt_ctl.options;
      }
      const parse_level = parseInt(lib_level);
      let found_lib;
      if (lib_options) {
        found_lib = lib_options.find((option) => option.value === parse_level);
      }
      if (params?.logLevels?.values?.libvirt?.libvirt_ctl?.level) {
        params.logLevels.values.libvirt.libvirt_ctl.level = found_lib.name.toLowerCase();
      }
      let codesys_level;
      let codesys_options;
      if (params?.logLevels?.values?.codesys?.codesys_ctl?.options) {
        codesys_level = params.logLevels.values.codesys.codesys_ctl.level;
        codesys_options = params.logLevels.values.codesys.codesys_ctl.options;
      }

      const parse_level1 = parseInt(codesys_level);
      let found_codesys;
      if (codesys_options) {
        found_codesys = codesys_options.find((option) => option.value === parse_level1);
      }
      if (params?.logLevels?.values?.codesys?.codesys_ctl?.level) {
        params.logLevels.values.codesys.codesys_ctl.level = found_codesys.name.toLowerCase();
      }
      state.monitoringAndLoggingSettings = params;
      state.monitoringAndLoggingSettingsInitialValues = cloneDeep(params);
    },
    REMOVE_RC_FROM_NODE(state, index) {
      if (state.nodeData.remoteConnections[index]) {
        state.nodeData.remoteConnections.splice(index, 1);
      }
    },
    SET_IS_UPDATE_FLAG(state, isUpdate) {
      state.isUpdate = isUpdate;
    },
    RESET_NODE_DATA(state) {
      state.nodeData = new NodeModel({});
      state.timezoneName = '';
    },
  },
  getters: {
    getNodeData: (state) => state.nodeData,
    list: (state) => state.nodes,
    count: (state) => state.count,
    selectedNodesList: (state) => state.selectedNodesList,
    getMonitoringAndLoggingSettingsDialogState: (state) => state.monitoringAndLoggingSettingsDialog,
    getLogLevelList: (state) => state.logLevelList,
    getInitialLogLevelList: (state) => state.initialLogLevelList,
    getTimezoneName: (state) => state.timezoneName,
    getMonitoringAndLoggingSettings: (state) => state.monitoringAndLoggingSettings,
    getMonitoringAndLoggingSettingsInitialValues: (state) => state.monitoringAndLoggingSettingsInitialValues,
    isSaveEnabled: (state) => state.isSaveEnabled,
    isViewSystemSaveEnabled: (state) => state.isViewSystemSaveEnabled,
    isDockerSystemSaveEnabled: (state) => state.isDockerSystemSaveEnabled,
    isLogLevelSaveEnabled: (state) => state.isLogLevelSaveEnabled,
    isNodeUpdating: (state) => state.isUpdate,
    getOldNodeRemoteConnections: (state) => state.oldNodeData?.remoteConnections || [],
    getTimeout: (state) => state.timeout,
  },
  actions: {
    async create_new_node({ commit, dispatch }, { nodeData, rc }) {
      store.dispatch('utils/_api_request_handler/close_toast');
      try {
        nodeData.remoteConnections = rc;
        commit('SET_NODE_DATA', await NodesApiService.createNewNode(nodeData));
        await dispatch('node-tree/update_unassigned', {}, { root: true });
      } catch (e) {
        const { data } = e.response;
        const err = data[0].errorCode ? data[0].errorCode : 'nerve_unexp_server_002';
        await store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: `errorMessages.${err}`,
          color: 'red',
          showClose: true,
        });
        throw e.response.data;
      }
    },
    async update_node({ commit, dispatch }, { nodeData, rc }) {
      store.dispatch('utils/_api_request_handler/close_toast');
      try {
        commit('SET_REMOTE_CONNECTION', rc);
        nodeData.remoteConnections = rc;
        await NodesApiService.updateNode(nodeData);
        commit('SET_NODE_DATA', nodeData);
        dispatch('node-tree/update_type_node', nodeData, { root: true });
      } catch (e) {
        if (e && e.response && e.response.data) {
          const { data } = e.response;
          const err = data[0].errorCode ? data[0].errorCode : 'nerve_unexp_server_003';
          store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: `errorMessages.${err}`,
            color: 'red',
            showClose: true,
          });
          throw e.response.data;
        }
        throw e;
      }
    },
    async get_node_by_id({ commit }, nodeId) {
      const nodeData = nodeId === 'new' ? {} : await NodesApiService.getNodeById(nodeId, { fetchFreshNodeData: false });
      commit('SET_NODE_DATA', nodeData);
      commit('SET_OLD_NODE', nodeData);
    },

    set_is_update_flag({ commit }, isUpdate) {
      commit('SET_IS_UPDATE_FLAG', isUpdate);
    },

    async fetch_nodes_for_deploy({ commit }, parameters = {}) {
      function handleOrder({ sortBy, sortDesc }) {
        if (!sortBy || !sortDesc) {
          return { created: 'desc' };
        }

        if (!sortBy.length || !sortDesc.length) {
          return { created: 'desc' };
        }

        return { [sortBy[0]]: sortDesc[0] ? 'desc' : 'asc' };
      }

      const params = {
        filterBy: { name: parameters.search },
        limit: parameters.itemsPerPage || 10,
        page: parameters.page || 1,
        order: handleOrder(parameters),
      };

      commit('SET_NODES', await NodesApiService.fetchDeployNodes({ ...parameters.workload, params }));
    },

    async fetch({ state, commit }, parameters = {}) {
      function handleOrder({ sortBy, sortDesc }) {
        if (!sortBy || !sortDesc) {
          return { created: 'desc' };
        }

        if (!sortBy.length && !sortDesc.length) {
          return { created: 'desc' };
        }

        return { [sortBy[0]]: sortDesc[0] ? 'desc' : 'asc' };
      }
      if (state.delete && state.nodes.length === 0 && parameters.page > 1) {
        parameters.page -= 1;
      }
      const params = {
        filterBy: {
          name: parameters.search,
          capabilities: parameters.capabilities,
          labels: parameters.labels,
        },
        limit: parameters.itemsPerPage || 10,
        page: parameters.page || 1,
        order: handleOrder(parameters),
      };

      commit('SET_NODES', await NodesApiService.fetchNodes({ params }));
    },
    async remove({ state, commit, dispatch }, node) {
      state.delete = true;
      await dispatch('node-tree/remove_type_node', node, { root: true });
      commit('REMOVE_NODE', node.id);
      store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'nodes.list.deleteItem',
        color: 'success',
        showClose: true,
      });
    },

    async removeRcFromNode({ commit }, params) {
      commit('REMOVE_RC_FROM_NODE', params.index);
    },

    mqtt_update_node({ commit }, node) {
      commit('SET_NODE_IN_LIST', node);
    },
    select_node({ commit }, node) {
      commit('SELECTED_NODE_LIST', node);
    },
    remove_selected_node({ commit }, node) {
      commit('REMOVE_SELECTED_NODE', node);
    },
    deselect_nodes_on_page({ commit }) {
      commit('DESELECT_NODES_ON_PAGE');
    },
    select_nodes_on_page({ commit }) {
      commit('SELECT_NODES_ON_PAGE');
    },
    deselect_all_nodes({ commit }) {
      commit('DESELECT_ALL_NODES');
    },
    async reboot({ commit, dispatch }, { serialNumber, name }) {
      try {
        store.dispatch('utils/_api_request_handler/show_loading_bar');
        await NodesApiService.reboot(serialNumber);
        store.dispatch(
          'utils/_api_request_handler/show_message_box',
          {
            title: '',
            text: i18n.t('nodes.reboot.rebootStarted', { name, serialNumber }),
          },
          { root: true },
        );
      } catch (err) {
        const { data } = err.response;
        const errorCode = data && data[0] && data[0].errorCode ? data[0].errorCode : undefined;
        const text = errorCode
          ? `errorMessages.${errorCode}`
          : i18n.t('nodes.reboot.notAllowed', {
              name,
              serialNumber,
            });
        store.dispatch(
          'utils/_api_request_handler/show_message_box',
          { title: 'errorDialog.title', text },
          { root: true },
        );
      } finally {
        store.dispatch('utils/_api_request_handler/close_loading_bar');
      }
    },
    async log_and_metrics({ commit }, param) {
      try {
        if (param.connectionStatus) {
          commit('LOG_AND_METRICS', await NodesApiService.getMonitoringAndLoggingSettings(param.serialNumber));
        } else {
          commit('LOG_AND_METRICS', {
            dockerMonitoring: false,
            systemMonitoring: false,
            dockerLogging: false,
          });
        }
      } catch (error) {
        commit('LOG_AND_METRICS', {
          dockerMonitoring: false,
          systemMonitoring: false,
          dockerLogging: false,
        });
        throw error;
      }
    },
    async monitoring_and_logging_settings({ commit }, param) {
      try {
        if (param.connectionStatus) {
          commit(
            'MONITORING_LOGGING_SETTINGS',
            await NodesApiService.getMonitoringAndLoggingSettingsV2(param.serialNumber),
          );
        } else {
          commit('MONITORING_LOGGING_SETTINGS', {
            dockerMonitoring: false,
            systemMonitoring: false,
            dockerLogging: false,
          });
        }
      } catch (error) {
        commit('MONITORING_LOGGING_SETTINGS', {
          dockerMonitoring: false,
          systemMonitoring: false,
          dockerLogging: false,
        });
        throw error;
      }
    },
    async update_monitoring_and_logging_settings_V2({ commit }, payload) {
      await NodesApiService.monitoringAndLoggingSettingsV2(payload);
      commit('SET_INIT_VALUE_LOG_METRICS_V2_AFTER_SAVE', {
        loggingMonitoring: {
          dockerMonitoring: payload.data.loggingMonitoring.dockerMonitoring,
          systemMonitoring: payload.data.loggingMonitoring.systemMonitoring,
          dockerLogging: payload.data.loggingMonitoring.dockerLogging,
        },
        logLevels: {
          values: {
            codesys: { codesys_ctl: { level: payload.data.logLevels.codesys_ctl } },
            libvirt: { libvirt_ctl: { level: payload.data.logLevels.libvirt_ctl } },
            ovdm: { ovdm: { level: payload.data.logLevels.ovdm } },
          },
        },
      });
    },
    async update_log_and_metrics({ commit }, payload) {
      await NodesApiService.applyMonitoringAndLoggingSettings(payload);
      commit('SET_INIT_VALUE_LOG_METRICS_AFTER_SAVE', {
        dockerMonitoring: payload.data.dockerMonitoring,
        systemMonitoring: payload.data.systemMonitoring,
        dockerLogging: payload.data.dockerLogging,
      });
    },
    update_monitoring_and_logging_params({ commit, getters }, payload) {
      // only if dialog is open state will be updated
      if (getters.getMonitoringAndLoggingSettingsDialogState) {
        commit('SHOW_MONITORING_LOGGING_SETTINGS_DIALOG', payload.params.values);
      }
    },
    async get_log_level_list({ commit }, params) {
      const logLevel = await NodesApiService.getLogLevelList(params);
      commit('SET_LOG_LEVEL_LIST', logLevel);
    },
    set_save_enabled({ commit }, params) {
      if (params.isChangedLogLevel || params.isViewChanged || params.isChangedDockerSystem) {
        commit('SET_SAVE_ENABLED', true);
      }
    },
    set_view_system_save_enabled({ commit }, params) {
      if (params.isViewChanged) {
        commit('SET_VIEW_SYSTEM_SAVE_ENABLED', true);
      }
    },
    set_docker_system_save_enabled({ commit }, params) {
      if (params.isChangedDockerSystem) {
        commit('SET_DOCKER_SYSTEM_SAVE_ENABLED', true);
      }
    },
    set_log_system_save_enabled({ commit }, params) {
      if (params.isChangedLogLevel) {
        commit('SET_LOG_SYSTEM_SAVE_ENABLED', true);
      }
    },
    disable_save_button({ commit }, params) {
      if (!params.isChangedLogLevel || !params.isViewChanged || !params.isChangedDockerSystem) {
        commit('SET_SAVE_ENABLED', false);
      }
    },
    disable_view_system_button({ commit }, params) {
      if (!params.isViewChanged) {
        commit('SET_VIEW_SYSTEM_SAVE_ENABLED', false);
      }
    },
    disable_docker_system_button({ commit }, params) {
      if (!params.isChangedDockerSystem) {
        commit('SET_DOCKER_SYSTEM_SAVE_ENABLED', false);
      }
    },
    disable_log_system_button({ commit }, params) {
      if (!params.isChangedLogLevel) {
        commit('SET_LOG_SYSTEM_SAVE_ENABLED', false);
      }
    },
    set_timeout({ commit }, params) {
      commit('SET_TIMEOUT', params);
    },
    clear_node_state({ commit }) {
      commit('RESET_NODE_DATA');
    },
  },
};
