/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2023. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */
import Vue from 'vue';
import { ActivityLogsApiService } from '../../services/api';
import ActivityLogModel from '@/model/activity-log/activity-log.model';

export default {
  state: {
    activityLogs: [],
    count: 0,
  },
  mutations: {
    SET_ACTIVITY_LOGS(state, value) {
      value.data.forEach((element) => {
        state.activityLogs.push(new ActivityLogModel(element));
      });
      state.count = value.count;
    },

    SET_ACTIVITY_LOGS_TO_EMPTY(state) {
      state.activityLogs = [];
    },
  },
  getters: {
    list: (state) => state.activityLogs,
    count: (state) => state.count,
  },
  actions: {
    async fetchActivityLogs({ commit, state }, params) {
      if (params.page === 1) {
        state.activityLogs = [];
      }
      commit('SET_ACTIVITY_LOGS', await ActivityLogsApiService.fetchActivityLogs(params));
    },

    async download_activity_log_for_one_workload({ commit }, params) {
      try {
        const exportUrl = `${window.location.pathname.split('/')[0]}/nerve/v2/node/${params.serialNumber}/versions/${
          params.versionId
        }/activity-logs/download`;
        window.location = exportUrl;
      } catch (e) {
        Vue.prototype.$log.debug(e);
      }
    },

    async download_activity_log_for_all_workload({ commit }, params) {
      try {
        const exportUrl = `${window.location.pathname.split('/')[0]}/nerve/v2/node/${
          params.serialNumber
        }/workloads/activity-logs/download`;
        window.location = exportUrl;
      } catch (e) {
        Vue.prototype.$log.debug(e);
      }
    },

    set_activity_logs_to_empty({ commit }) {
      commit('SET_ACTIVITY_LOGS_TO_EMPTY');
    },
  },
};
