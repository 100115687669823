/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2021. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

/**
 * List topics that authenticated user should subscribe to
 *
 * @param userId - Authenticated user id
 * @param sessionToken - second part of sessionId (sessionId.split("-")[1])
 * @returns {[{topic: string}, {topic: string}]}
 */
const listAuthUserTopics = () => [
  // topic used to receive info if an error has been occurred when try to start/stop
  // deployed workload,or when Docker image is being downloaded
  {
    matchBy: 'service_workload/evt',
    topic: 'oblo/+/session/+/svc/service_workload/evt',
    action: 'node-tree/mqtt_handle_node_response',
  },
];
/**
 * List topics that all users should be subscribed to
 */
const listAlwaysSubscribedTopics = () => [
  // online/offline status of the Node
  {
    matchBy: 'notifier',
    topic: 'oblo/all/admin/svc/notifier/evt',
    action: 'node-tree/mqtt_any_node_updated',
  },
];
/**
 * List Node topics that client will subscribe to
 *
 * @param userId - current (selected) node user id
 * @param serialNumber - current (selected) serialNumber
 * @param sessionId - authenticated user sessionId
 * @returns {[{topic: string}, {topic: string}, {topic: string}]}
 */
const listNodeTopics = ({ userId = '+', serialNumber = '+' } = {}) => [
  // ohm events from the specific Node
  {
    matchBy: 'ohm/evt',
    topic: `oblo/${userId}/gtw/${serialNumber}/ohm/evt`,
    action: 'node-tree/mqtt_selected_node_deployed_wl_updated',
  },
  // NodePersistentDataEvent should be listened to update 'ovdmStarted' property in a tree model
  {
    matchBy: 'ovdm/evt',
    topic: `oblo/+/gtw/${serialNumber}/ovdm/evt`,
    action: 'node-tree/mqtt_selected_node_updated',
  },
];

/** Snapshot topic
 *
 * @param serialNumber - current (selected) serialNumber
 * @returns {[{topic: string}]}
 */
const snapshotTopic = ({ serialNumber = '+' } = {}) => [
  // snapshot events from the specific node
  {
    matchBy: 'snapshot',
    topic: `nerve/node/${serialNumber}/snapshot`,
    action: 'vm-snapshot/mqtt_snapshot',
  },
];

/** Backups topic
 *
 * @param serialNumber - current (selected) serialNumber
 * @returns {[{topic: string}]}
 */
const backupsTopic = ({ serialNumber = '+' } = {}) => [
  // backup events from the specific node
  {
    matchBy: 'workload_backup',
    topic: `nerve/node/${serialNumber}/workload_backup`,
    action: 'vm-backups/mqtt_backups',
  },
];

/**
 * List Cloud topics that client will subscribe to
 *
 * @returns {[{topic: string}, {topic: string}, {topic: string}]}
 */
const listCloudTopics = () => [
  // info about campaign/task updates
  { matchBy: 'bom/evt', topic: 'oblo/all/admin/svc/bom/evt', action: 'bom-mqtt-topic/mqtt_cloud' },
];

/**
 * Initiate remote connection
 *
 * @returns {[{topic: string}, {topic: string}, {topic: string}]}
 */
const initRemoteConnectionTopics = () => [
  {
    matchBy: 'remote_connection',
    topic: 'oblo/svc/remote_connection/+/evt',
    action: 'remote-connection/mqtt_rc',
  },
];

/** DNA topic
 *
 * @param serialNumber - current (selected) serialNumber
 * @returns {[{topic: string}]}
 */
const dnaTopic = ({ serialNumber = '+' } = {}) => [
  // dna events from the specific node
  // TODO matchBy should be unique param from topic field. Because of that topic should be changed
  // F.e. topic: `nerve/node/${serialNumber}/dna` or `nerve/node/${serialNumber}/dna/evt'
  {
    name: 'dna_status_evt',
    matchBy: 'evt',
    topic: `nerve/node/${serialNumber}/evt`,
    action: 'dna-mqtt/mqtt_dna',
  },
];

export default {
  alwaysSubscribed: listAlwaysSubscribedTopics,
  node: listNodeTopics,
  cloud: listCloudTopics,
  authUser: listAuthUserTopics,
  snapshot: snapshotTopic,
  initRc: initRemoteConnectionTopics,
  backups: backupsTopic,
  dna: dnaTopic,
};
