/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

import BaseApiService from './base.api-service';

const PATH = '/nerve/tree-node/';

const ENDPOINTS = {
  PARENT: 'parent',
  CHILDREN_BY_TYPE: 'child-type',
  TYPE: 'type',
  UPSERT_MANY: 'upsert-many',
  MOCK: 'mock',
  GET_FULL_TREE: 'getFullTree',
};

class NodeTreeApiService extends BaseApiService {
  getByParentId(parentId) {
    return this.get(`${PATH}${ENDPOINTS.PARENT}/${parentId}`, { authRequired: true });
  }

  getChildrenByType(type) {
    return this.get(`${PATH}${ENDPOINTS.CHILDREN_BY_TYPE}/${type}`, { authRequired: true });
  }

  getByType(type) {
    return this.get(`${PATH}${ENDPOINTS.TYPE}/${type}`, { authRequired: true });
  }

  upsertMany(treeNodes) {
    return this.put(`${PATH}${ENDPOINTS.UPSERT_MANY}`, treeNodes, { authRequired: true });
  }

  mock(treeNode) {
    return this.post(`${PATH}${ENDPOINTS.MOCK}`, { treeNodeData: treeNode }, { authRequired: true });
  }

  deleteTreeNodesRecursively(id) {
    return this.delete(`${PATH}${id}`, { authRequired: true });
  }

  getFullTree() {
    return this.get(`${PATH}${ENDPOINTS.GET_FULL_TREE}`, { authRequired: true });
  }
}

export default new NodeTreeApiService();
