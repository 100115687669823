/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2022. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

/* eslint-disable class-methods-use-this */
export default class SnapshotModel {
  /**
   * Creates an instance of SnapshotModel.
   * @param {Object} snapshot - Snapshot object
   * @class SnapshotModel
   * @property {string} name - Snapshot name
   * @property {string} description - Snapshot description
   * @property {string} vnState - workload state
   * @property {string} date - Snapshot date
   */
  constructor(snapshot = {}) {
    this.name = snapshot.name || '';
    this.description = snapshot.description || '-';
    this.vmState = snapshot.vmState || '';
    this.date = this.transformTimestampToDate(snapshot.date) || '';
  }

  transformTimestampToDate(timestamp) {
    const transformedTime = new Date(timestamp * 1000);

    const month = this.addLeadingZero(transformedTime.getMonth() + 1);
    const date = this.addLeadingZero(transformedTime.getDate());
    const hours = this.addLeadingZero(transformedTime.getHours());
    const minutes = this.addLeadingZero(transformedTime.getMinutes());
    const seconds = this.addLeadingZero(transformedTime.getSeconds());

    return `${date}-${month}-${transformedTime.getFullYear()} ${hours}:${minutes}:${seconds}`;
  }

  addLeadingZero(value) {
    return `0${value}`.slice(-2);
  }
}
